<template>
    <div class="form-detail-container content-bg">
        <div class="form-detail-wrap">
            <div class="form-detail">
                <template v-if="!loadingTable" >
                    <!--表单-->
                    <approval-form-data
                        ref="formRef"
                        :rule-form="ruleForm"
                        :rule-form-data="ruleFormData"
                        :tipContent="tipContent"
                        :tipContent1="tipContent1"
                        :onlyCode="onlyCode"
                        :leaveDurationDetailsBtnShow="leaveDurationDetailsBtnShow"
                        @showTreeDialog="showFormTreeDialog"
                        @showTreeDialogStu="showFormTreeDialogStu"
                        @handleChange="handleChange"
                        @dateHalfChange="handleDateHalfChange"
                        @getSubOptionsData="getSubOptionsData"
                        @addressChange="addressChange"
                        @changeDuration="changeDuration"
                        @getIsRepeatCheckDate="getIsRepeatCheckDate"
                        @isProcessDisabled="isProcessDisabled"
                        @leaveDurationDetailsShow="handleLeaveDurationDetailsShow"
                        @getParentList="getParentList"
                        class="approval-form-data"
                    />
                    <!--流程-->
                    <approval-process
                        :rule-form-data="ruleFormData"
                        :show-process="showProcess"
                        :process-tips="processTips"
                        :process-user-content="processUserContent"
                        @showProcessTreeDialog="showProcessTreeDialog"
                        @delProcessPeople="delProcessPeople"
                        class="approval-process"
                    />
                </template>
                <el-skeleton
                    :loading="loadingTable"
                    :rows="10"
                    animated
                    class="approval-form-data"
                />
            </div>
        </div>
        <div class="btn-group">
            <el-button
                :disabled="!showProcess"
                @click="submitForm"
                type="primary"
            >{{ OAQueryData.id && OAQueryData.dataTitle ? "修改" : "提交" }}</el-button>
            <el-button
                @click="handleBack"
                type="enquiry"
            >取消</el-button>
        </div>
        <tree-shape
            ref="treeShape"
            v-if="dialogObj1.dialogVisible"
            :title="dialogObj1.title"
            :multiple="multiple"
            :check-user-only="true"
            :show="dialogObj1.dialogVisible"
            :list="selTreeOptList"
            :list1="selTreeOptList1"
            :selectObjData="selectObjData"
            @getNextLevelTreeList="getNextLevelTreeList"
            @getNextLevelTreeList1="getNextLevelTreeList1"
            @determine="determine"
            @closeDialog="closeDialog"
        >
        </tree-shape>
        <xk-tree-shape
            v-if="dialogObj.dialogVisible"
            :title="dialogObj.title"
            :multiple="multiple"
            :check-user-only="true"
            :show="dialogObj.dialogVisible"
            :list="selTreeOptList"
            :selectObjData="selectObjData"
            @getNextLevelTreeList="getNextLevelTreeList"
            @determine="determine"
            @closeDialog="closeDialog"
        >
        </xk-tree-shape>
        <!-- 请假时长明细弹窗 -->
        <dialog-wrapper :dialogObj="leaveDurationDetailsDialogObj" @handleClose="handleLeaveDurationDetailsClose">
            <leave-duration-details
                :key="+new Date()"
                :params="leaveDurationDetailsDialogObj.data"
                @close="handleLeaveDurationDetailsClose"
            ></leave-duration-details>
        </dialog-wrapper>
    </div>
</template>

<script>
import SelTree from "./Sub/SelTree1";
import TreeShape from "./CoworkingCampusOAOAApprovalSubmit/TreeShape/src/index.vue";
import {CoworkingCampusOAVacationManagementModel} from "@/models/CoworkingCampusOAVacationManagement.js";
import {
    DialogWrapper,
    // 功能组件
    Loading,
    Error,
    debounce,
} from "common-local";
// 逻辑运算
import {mapMutations, mapState} from "vuex";
import ApprovalFormData from "@/components/scrollWrapper/CoworkingCampusOAOAApproval/formDetail/approvalFormData.vue";
import ApprovalProcess from "@/components/scrollWrapper/CoworkingCampusOAOAApproval/formDetail/approvalProcess.vue";
import LeaveDurationDetails from "@/components/scrollWrapper/CoworkingCampusOAOAApproval/formDetail/leaveDurationDetails.vue";
import {CampusSafetyModel} from "@/models/CampusSafety";
import {CampusOAModel} from "@/models/CampusOA";
import {CommonModel} from "@/models/Common.js";
import {parseTime, removeTreeShapeDialog} from "@/libs/utils.js";

// 手机号正则校验
const phoneNumber = (rule, value, callback) => {
    const reg = /^1[3|4|5|7|8][0-9]{9}$/;
    if (value !== null && String(value).trim() !== "" && !reg.test(value)) {
        callback("手机号格式不正确");
    } else {
        callback();
    }
};
// 数字正则校验
const number = (rule, value, callback) => {
    const reg = /^\+?[1-9]\d*$/;
    if (value !== null && String(value).trim() !== "" && !reg.test(value)) {
        callback("只允许输入大于0的正整数");
    } else {
        callback();
    }
};
// 身份证正则校验
const IDCard = (rule, value, callback) => {
    const reg =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (value !== null && String(value).trim() !== "" && !reg.test(value)) {
        callback("身份证号格式不正确");
    } else {
        callback();
    }
};

export default {
    name: "CoworkingCampusOAOAApprovalSubmit",
    components: {
        Loading,
        Error,
        ApprovalFormData,
        ApprovalProcess,
        LeaveDurationDetails,
        SelTree,
        DialogWrapper,
        TreeShape
    },
    data() {
        return {
            isdisabledStartTime:true,
            // 特殊处理
            specialHandling: [
                {
                    schoolId: '311',
                    rules: {
                        value1: {
                            show: true,
                            labelName: '请假日期',

                        },
                        value2: {
                            show: false,
                            labelName: '结束时间',
                        }
                    }
                },
                {
                    schoolId: '222',
                    rules: {
                        value1: {
                            show: true,
                            labelName: '请假日期',

                        },
                        value2: {
                            show: false,
                            labelName: '结束时间',
                        }
                    }
                }
            ],
            formTitle: '',
            /**
             * title 名称
             * multiple 是否多学
             * treeData 树形请求数据
             * facultyStaff 教职工
             * studentParents 学生家长
             * staffParents 教职工及学生家长
             * department 部门
             * selTreeOptList 请求数据
             * selectObjData 选中数据
             * setTreeAuxiliaryData 返回后的辅助数据
             * assembliesPersonType 审核/抄送人状态
             * assembliesPerson 抄送人已经选择数据
             * */
            title: "可选择",
            multiple: false,
            treeData: {
                schoolId: '',
                type: '',
                parentId: '',
                name: '',
                organType: '',
                haveDatePermission: '1',
                peopleStatus: '1', // 教师：1在职，2离职；学生：1在校，2毕业，3转学
            },
            facultyStaff: {
                type: "teacher,organ",
                organType: "1,5",
            },
            studentParents: {
                type: "student,organ",
                organType: "2,3,4,5",
            },
            staffParents: {
                type: "teacher,student,organ",
                organType: "1,2,3,4,5",
            },
            department: {
                type: "organ",
                organType: "1",
            },
            selTreeOptList: [],
            selTreeOptList1: [],
            selectObjData: [],
            setTreeAuxiliaryData: {
                key: "",
                data: [],
                selList: "",
            },
            assembliesPersonType: "",
            assembliesPerson: [],

            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            ruleFormData: [],
            ruleForm: {},
            ruleFormInfoData: [],
            applyTeacherId: '',
            // 人员选择弹窗
            dialogObj: {
                title: "选择年级/班级/学生",
                dialogVisible: false,
                width: "600px",
                key: 1,
            },
            dialogObj1: {
                title: "选择年级/班级/学生",
                dialogVisible: false,
                width: "600px",
                key: 1,
            },
            selTreeOpt: {}, // 树形组件属性选择器
            formInfo: {
                list: [],
            }, // 表单数据
            showProcess: false,
            processTips: "必填信息填写后，流程将自动显示",
            processUserContent: [], // 流程数据
            teacherTreeData: [], // 教师组织架构数据
            studentTreeData: [], // 学生组织架构数据
            allTreeData: [], // 全部组织架构数据
            isRepeate: false, //是否请假时间重复
            schoolProcessInfo: {},
            speciLeaveType: '',  // 请假类型的id
            cLeaveDay: {
                residue: "", // 剩余调休时长
                name: "", // 请假类型名称
                unit: "", // 调休单位 0：没有设置调休规则 1：天 2：小时
            },
            tipContent: {}, // 校验时间重复的内容信息
            tipContent1:'',
            leaveDurationDetailsBtnShow: false, // 请假时长明细按钮显示状态
            leaveDurationDetailsDialogObj: { // 请假时长明细弹窗
                title: '查看明细',
                dialogVisible: false,
                width: 'auto',
                data: {}
            },
            onlyCode: '',
            vacationDuration: {}, // 请假时长数据
            overLeaveTime: 0,  // 余额
            showBalance: false, //是否展示余额还有几天
            isShowRepeateForm: false,  // 是否有进行中的表单
            useVacationList: [],   // 假期列表
            adminVacationList: [],   // admin的假期列表
            editSpproveStats: '',  // 获取余额时的假条状态
            isOverLeaveUnit: false,   // 提交时是否超过余额
            isSubmitBtn: true,   // 获取余额是否正常
            isDisabledBtn: false,  // 是否禁用开始时间、结束时间
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            OAQueryData: (state) => state.OAQueryData,
        }),
    },
    created() {
        this.init();
    },
    methods: {
        ...mapMutations(["setOAQueryData"]),
        async init() {
            this.formTitle = this.$route.query.formName;
            let id = this.OAQueryData.formId;
            this.treeData.schoolId = this.schoolId;
            if (this.OAQueryData.dataTitle && this.OAQueryData.id) {
                // 获取表单详情数据
                await this.getFormInfoData(
                    this.OAQueryData.id,
                    this.OAQueryData.dataTitle,
                );
            }
            setTimeout(() => {
                this.getFormOptData(id);
            }, 100)
        },

        /*
            初始化数据
            getFormInfoData            修改时获取表单填写内容
            getFormOptData             获取表单配置数据，拼装表单渲染数据
            setDefaultStringValue      输入框/单选/日期回显
            setDefaultArrayValue       关联审批单回显
            setDefaultPeopleValue      人员回显
            setDefaultAddressValue     地址回显
            setDefaultRadioValue       单选回显
            setDefaultMultiValue       多选回显
            getParentList              获取接送人下拉列表
            getSchoolProcessApplyList  获取关联审批单列表
         */
        async getFormInfoData(id, dataTitle) {
            const campusOA = new CampusOAModel();
            await campusOA
                .getProcessApplyInfo({
                    id: id,
                    dataTitle: dataTitle,
                })
                .then((res) => {
                    this.handleRes(res, async () => {
                        let ruleFormInfoData = res.data.data.schoolProcessApplyInfo.applyContent;
                        console.log("[getFormInfoData]ruleFormInfoData:", this._.cloneDeep(ruleFormInfoData))

                        // 检查假期单位是否被改变
                        if (ruleFormInfoData.leaveType && ruleFormInfoData.leaveType.displayValue && ruleFormInfoData.leaveType.displayValue.value) {
                            let isLeaveTypeChanged = false
                            let leaveTypeList = await this.getLeaveType();
                            let nowLeaveTypeInfo = leaveTypeList.find(e => e.value === ruleFormInfoData.leaveType.value)
                            if (nowLeaveTypeInfo) {
                                ruleFormInfoData.leaveType.displayValue.label = nowLeaveTypeInfo.label
                                if (ruleFormInfoData.leaveType.displayValue.leaveUnit !== nowLeaveTypeInfo.leaveUnit) {
                                    isLeaveTypeChanged = true
                                    ruleFormInfoData.leaveType.displayValue.leaveUnit = nowLeaveTypeInfo.leaveUnit
                                    setTimeout(() => {
                                        this.$message.warning('请假单位已改变，请重新选择时间')
                                    }, 1000);
                                }
                            }
                            if (isLeaveTypeChanged) {
                                ruleFormInfoData.leaveStartTime.value = ""
                                if (ruleFormInfoData.leaveStartTime.hasOwnProperty("noonType")) {
                                    ruleFormInfoData.leaveStartTime.noonType = "1"
                                }
                                ruleFormInfoData.leaveEndTime.value = ""
                                if (ruleFormInfoData.leaveEndTime.hasOwnProperty("noonType")) {
                                    ruleFormInfoData.leaveEndTime.noonType = "1"
                                }
                                ruleFormInfoData.leaveDuration.value = ""
                            }

                        }
                        this.applyTeacherId = res.data.data.applyTeacherId
                        this.ruleFormInfoData = ruleFormInfoData
                        console.log("[getFormInfoData]this.ruleFormInfoData:", this.ruleFormInfoData)
                        this.schoolProcessInfo = res.data.data.schoolProcessApplyInfo;

                        this.editSpproveStats = res.data.data.schoolProcessApplyInfo.approveStats;
                    });
                });
        },
        async getFormOptData(id) {
            let fromInfoId;
            const campusOA = new CampusOAModel();
            let _this = this
            const specialSchools = this.specialHandling.map(item => {
                return item.schoolId
            });

            let specialIdx = specialSchools.findIndex(item => {
                return item == this.schoolId;
            });
            const contentItem = specialIdx >=0 ? this.specialHandling[specialIdx] : {};

            console.log(specialIdx,contentItem,'contentItem==')

            await campusOA.getFormInfoData(id).then((res) => {
                if (res.data.code === "200") {
                    this.formInfo = res.data.data;
                    this.$emit('setTitle', this.formInfo.formName)
                    fromInfoId = res.data.data.fromInfo;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
            await campusOA.getContentDetails({id: fromInfoId}).then(async (res) => {
                if (res.data.code == "200") {
                    this.formInfo.list = res.data.data.content.list;
                    for (let item of this.formInfo.list) {
                        let obj = JSON.parse(JSON.stringify(item));
                        // 拼装正则校验
                        obj.rules = [
                            {
                                required: item.required,
                                message:
                                    item.ruleError ||
                                    item.placeholder ||
                                    "该字段不能为空" ,
                                trigger: ["blur", "change"],
                            },
                        ];
                        switch (item.compType) {
                            // 申请人
                            case "applicant":
                                obj.key = item.key;
                                obj.type = "selectTree";
                                await this.setDefaultPeopleValue(obj);
                                // 如果有换班套件，申请人强制只能选一个教职工
                                let haveKitsShiftChange = this.formInfo.list.find(e => e.key === 'kitsShiftChange')
                                if (haveKitsShiftChange) {
                                    obj.options = "1"
                                    obj.selectableRange = "1"
                                }
                                break;
                            // 新申请人
                            case "applicantStu":
                                obj.key = item.key;
                                obj.type = "selectTreeStu";
                                this.setDefaultPeopleValue(obj);
                                // console.log("[getFormOptData][applicant]obj:", obj)
                                // 如果有换班套件，申请人强制只能选一个教职工
                                let haveKitsShiftChange1 = this.formInfo.list.find(e => e.key === 'kitsShiftChange')
                                if (haveKitsShiftChange1) {
                                    obj.options = "1"
                                    obj.selectableRange = "1"
                                }
                                break;
                            // 接送人
                            case "pickDropPersons":
                                obj.key = item.key;
                                obj.type = "select";
                                obj.options = [];
                                obj.showPickDropManage = true
                                this.setDefaultStringValue(obj);
                                break;
                            // 出入类型
                            case "entryExitSelect":
                                obj.key = item.key;
                                obj.type = "select";
                                obj.options.forEach((item) => {
                                    item.label = item.value;
                                });
                                this.setDefaultStringValue(obj);
                                break;
                            // 关联审批单
                            case "approvalFormSelect":
                                obj.multiple = true;
                                obj.key = item.key;
                                obj.type = "select";
                                this.getSchoolProcessApplyList(obj);
                                this.setDefaultArrayValue(obj);
                                break;
                            // 出校时间
                            case "departureTime":
                            // 返校时间
                            case "returnTime":
                                obj.key = item.key;
                                obj.type = "dateTime";
                                obj.status = "datetime";
                                obj["value-format"] = "yyyy-MM-dd HH:mm:ss";
                                obj["format"] = "yyyy-MM-dd HH:mm:ss";
                                this.setDefaultStringValue(obj);
                                break;
                            // 人员
                            case "personnelInput":
                                obj.key = item.id;
                                obj.type = "selectTree";
                                this.setDefaultPeopleValue(obj);
                                break;
                            // 多选
                            case "multipleSelect":
                                obj.key = item.id;
                                obj.type = "select";
                                obj.multiple = true;
                                this.setDefaultMultiValue(obj);
                                break;
                            // 单行文本
                            case "singleLineText":
                                obj.key = item.id;
                                obj.type = "input";
                                this.setDefaultStringValue(obj);
                                break;
                            // 多行文本
                            case "multiLineText":
                                obj.key = item.id;
                                obj.type = "textarea";
                                obj.rows = item.rows || 5;
                                this.setDefaultStringValue(obj);
                                break;
                            case "phoneNumber": // 手机号
                            case "number": // 数字
                            case "IDCard": // 身份证号
                                obj.key = item.id;
                                obj.type = "input";
                                obj.rules = [
                                    {
                                        required: item.required,
                                        message: "内容不能为空",
                                        trigger: "blur",
                                    },
                                    {
                                        validator:
                                            item.compType === "phoneNumber"
                                                ? phoneNumber
                                                : item.compType === "number"
                                                ? number
                                                : IDCard,
                                        trigger: "blur",
                                    },
                                ];
                                this.setDefaultStringValue(obj);
                                break;
                            // 上传
                            case "upload":
                                obj.key = item.id;
                                obj.type = item.compType;
                                console.log('1111111111111111111111111111,', this.ruleFormInfoData[
                                            obj.key
                                        ]);
                                if (this.ruleFormInfoData[obj.key]) {
                                    obj.uploadValue =
                                        this.ruleFormInfoData[
                                            obj.key
                                        ].displayValue;
                                    if(Array.isArray(this.ruleFormInfoData[obj.key].value)){
                                        this.$set(
                                            this.ruleForm,
                                            obj.key,
                                            this.ruleFormInfoData[
                                                obj.key
                                            ].value
                                        );
                                    }else{
                                        this.$set(
                                            this.ruleForm,
                                            obj.key,
                                            this.ruleFormInfoData[
                                                obj.key
                                            ].value.split(","),
                                        );
                                    }

                                } else {
                                    obj.uploadValue = [];
                                    this.$set(this.ruleForm, obj.key, []);
                                }
                                break;
                            // 附件上传
                            case "attachmentUpload":
                                obj.key = item.id;
                                obj.type = item.compType;
                                if (this.ruleFormInfoData[obj.key]) {
                                    obj.uploadValue = this.ruleFormInfoData[obj.key].displayValue;
                                    this.$set(this.ruleForm, obj.key, this.ruleFormInfoData[obj.key].displayValue);
                                } else {
                                    obj.uploadValue = [];
                                    this.$set(this.ruleForm, obj.key, []);
                                }
                                break;
                            // 地址
                            case "address":
                                obj.key = item.id;
                                obj.type = item.compType;
                                this.$set(this.ruleForm, obj.key, {
                                    label: [],
                                    value: [],
                                });
                                // 获取省份数据
                                this.getOptionsData(obj);
                                break;
                            // 日期
                            case "dateTime":
                                obj.key = item.id;
                                obj.type = "dateTime";
                                if (obj.status === "datetime") {
                                    // 年月日 时分秒
                                    obj["value-format"] = "yyyy-MM-dd HH:mm:ss";
                                    obj["format"] = "yyyy-MM-dd HH:mm:ss";
                                } else if (obj.status === "daterange") {
                                    // 年月日
                                    obj.status = "date";
                                    obj["value-format"] = "yyyy-MM-dd";
                                    obj["format"] = "yyyy-MM-dd";
                                }
                                this.setDefaultStringValue(obj);
                                break;
                            case "radio":
                                obj.key = item.id;
                                obj.type = item.compType;
                                this.setDefaultRadioValue(obj);
                                break;
                            case "descriptionText":
                                obj.key = item.id;
                                obj.type = item.compType;
                                obj.label = "";
                                break;
                            /**
                             * 套件库
                             * 请假类型 kitsLeaveAbsence
                             * leaveType --请假类型
                             * leaveStartTime --请假开始时间
                             * leaveEndTime --请假结束时间
                             * leaveDuration --请假时长
                             * reasonLeave -- 请假事由
                             * */
                            case "kitsLeaveAbsence":
                                let ar1 = [
                                    "leaveType",
                                    "leaveStartTime",
                                    "leaveEndTime",
                                    "leaveDuration",
                                    "reasonLeave",
                                ];
                                for (var i = 0; i < ar1.length; i++) {
                                    let targetObj = this._.cloneDeep(obj);
                                    switch (ar1[i]) {
                                        case "leaveType":
                                            targetObj.key = ar1[i];
                                            targetObj.compType = "leaveType";
                                            targetObj.type = "select";
                                            targetObj.ele = "el-select";
                                            targetObj.label = "请假类型";
                                            targetObj.labelWidth = "90"
                                            targetObj.multiple = false;
                                            targetObj.rules = [
                                                {
                                                    required: true,
                                                    validator: (rule, value, callback) => {
                                                        if (value === "" || value == null) {
                                                            callback("请假类型不能为空");
                                                        } else if (this.cLeaveDay.unit === 0) {
                                                            callback("没有设置调休规则");
                                                        } else if (this.cLeaveDay.unit === 3) {
                                                            callback("您可使用当前调休周期内的调休余额");
                                                        } else if (this.isShowRepeateForm && !this.showBalance) {
                                                            callback("有进行中的审批单，不能提交新的审批单");
                                                        } else {
                                                            callback()
                                                        }
                                                    },
                                                },
                                            ];

                                            targetObj.options = await this.getLeaveType()
                                            this.setDefaultStringValue(
                                                    targetObj,
                                                );
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "leaveStartTime":
                                            console.log('leaveStartTime')
                                            targetObj.key = ar1[i];
                                            targetObj.compType =
                                                "leaveStartTime";
                                            targetObj.type = "dateTime";
                                            targetObj.status = "datetime";
                                            targetObj.label = specialIdx >= 0 ? contentItem.rules.value1.labelName : "开始时间";
                                            targetObj.labelWidth = "90"
                                            targetObj.disabled = this.OAQueryData.id && this.OAQueryData.dataTitle && this.schoolProcessInfo.excuseState =='2' ? true : false;
                                            targetObj["value-format"] =
                                                "yyyy-MM-dd HH:mm";
                                            targetObj["format"] =
                                                "yyyy-MM-dd HH:mm";
                                            targetObj.pickerOptions = {}
                                            targetObj.pickerOptions['showRange'] = targetObj['fixedTime'] || {}
                                            this.setDefaultStringValue(
                                                targetObj,
                                            );
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "leaveEndTime":
                                            targetObj.key = ar1[i];
                                            targetObj.compType = "leaveEndTime";
                                            targetObj.type = "dateTime";
                                            targetObj.status = "datetime";
                                            targetObj.label = specialIdx >= 0 ? contentItem.rules.value2.labelName : "结束时间";
                                            targetObj.show = specialIdx >= 0 ? contentItem.rules.value2.show : true;
                                            targetObj.labelWidth = "90";
                                            targetObj.pickerOptions = {}
                                            targetObj.pickerOptions['showRange'] = targetObj['fixedTime'] || {}
                                            targetObj["value-format"] =
                                                "yyyy-MM-dd HH:mm";
                                            targetObj["format"] =
                                                "yyyy-MM-dd HH:mm";
                                                targetObj.rules = [
                                                {
                                                    required: true,
                                                    validator: (
                                                        rule,
                                                        value,
                                                        callback,
                                                    ) => {
                                                        if (value == '' || value == null) {
                                                            callback('结束时间不能为空')
                                                        } else if (this.isRepeate) {
                                                            callback('该时间段已存在假条，请添加其他时间段。若两个时间段衔接，请保证衔接处的时间不同')
                                                        }else {
                                                            callback()
                                                        }
                                                    },
                                                    trigger: "change",
                                                },
                                            ];
                                            this.setDefaultStringValue(
                                                targetObj,
                                            );
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "leaveDuration":
                                            targetObj.key = ar1[i];
                                            targetObj.compType =
                                                "leaveDuration";
                                            targetObj.type = "input";
                                            targetObj.label = "时长";
                                            targetObj.labelWidth = "90"
                                            targetObj.unit =
                                                this.ruleFormInfoData.leaveType?.displayValue?.leaveUnit == "2" ? "小时" : "天";
                                            targetObj.disabled = true;
                                            targetObj.required = true;
                                            targetObj.rules = [
                                                {
                                                    required: true,
                                                    validator: (
                                                        rule,
                                                        value,
                                                        callback,
                                                    ) => {
                                                        if (value === 0) {
                                                            callback("请假时间在休息时间或放假时间内",);
                                                        } else if (!value) {
                                                            callback("请假时长不能为空",);
                                                        } else if (this.showBalance && this.ruleForm.leaveDuration > this.overLeaveTime) {
                                                            callback("超出剩余额度，请重新选择时间");
                                                        } else if (
                                                            targetObj.showLimit === 1 &&
                                                            this.ruleForm.applicant &&
                                                            this.ruleForm.applicant.findIndex(i => i.key === 'teacher') === -1 &&
                                                            this.vacationDuration.leaveUnit === 2 &&
                                                            this.ruleForm.leaveDuration < targetObj.limitDuration
                                                        ) {
                                                            // 开启请假时长限制后，针对学生请假单，用户提交请假单为小时，小于请假时长限制
                                                            callback(`请假时长不能低于${targetObj.limitDuration}个小时`);
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                    trigger: "change",
                                                },
                                            ];
                                            this.setDefaultStringValue(
                                                targetObj,
                                            );
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "reasonLeave":
                                            item.reasonLeaveRequired = item.reasonLeaveRequired || 0;
                                            targetObj.key = ar1[i];
                                            targetObj.compType = "reasonLeave";
                                            targetObj.type = "textarea";
                                            targetObj.label = "请假事由";
                                            targetObj.labelWidth = "90"
                                            targetObj.disabled = false;
                                            targetObj.maxLength = item.maxLength ?  item.maxLength : 50;

                                            targetObj.rules = [
                                                {
                                                    required:
                                                        item.reasonLeaveRequired ===
                                                        0
                                                            ? false
                                                            : true,
                                                    message: "请假事由不能为空",
                                                    trigger: ["blur", "change"],
                                                },
                                            ];
                                            targetObj.required =
                                                item.reasonLeaveRequired === 0
                                                    ? false
                                                    : true;
                                            this.setDefaultStringValue(
                                                targetObj,
                                            );
                                            this.ruleFormData.push(targetObj);
                                            break;
                                    }
                                }
                                break;
                            /**
                             * 套件库
                             * 请假类型 kitsLeaveAbsence
                             * leaveType --请假类型
                             * leaveStartTime --请假开始时间
                             * leaveEndTime --请假结束时间
                             * leaveDuration --请假时长
                             * reasonLeave -- 请假事由
                             * */
                            case "kitsSpecialLeave":
                                let arr = [
                                    "leaveType",
                                    "specialLeave",
                                    "specialLeaveTotalDuration",
                                    "reasonLeave",
                                ];
                                for (var i = 0; i < arr.length; i++) {
                                    let targetObj = this._.cloneDeep(obj);
                                    switch (arr[i]) {
                                        case "leaveType":
                                            targetObj.key = arr[i];
                                            targetObj.compType = "leaveType";
                                            targetObj.type = "select";
                                            targetObj.ele = "el-select";
                                            targetObj.label = "请假类型";
                                            targetObj.labelWidth = "90"
                                            targetObj.multiple = false;
                                            targetObj.rules = [
                                                {
                                                    required: true,
                                                    message: "请假类型不能为空",
                                                    trigger: ["blur", "change"],
                                                },
                                            ];
                                            targetObj.options =
                                                await this.getSpecialLeaveType();
                                            this.setDefaultStringValue(
                                                targetObj,
                                            );
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "specialLeave":
                                            targetObj.key = arr[i];
                                            targetObj.compType = "specialLeave";
                                            targetObj.required = false;
                                            targetObj.type = "specialLeave";
                                            targetObj.rules = [
                                                {
                                                    required: false,
                                                    message: "",
                                                    trigger: ["blur", "change"],
                                                },
                                            ];
                                            targetObj.vacationId = this.ruleForm.leaveType
                                            targetObj.value = this.ruleFormInfoData.specialLeave
                                            this.setDefaultStringValue(
                                                targetObj,
                                            );
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "specialLeaveTotalDuration":
                                            targetObj.key = arr[i];
                                            targetObj.compType =
                                                "specialLeaveTotalDuration";
                                            targetObj.type = "input";
                                            targetObj.label = "请假总时长";
                                            targetObj.labelWidth = "90"
                                            targetObj.unit = "小时";
                                            targetObj.disabled = true;
                                            targetObj.required = false;
                                            targetObj.rules = [
                                                {
                                                    required: true,
                                                    validator: (
                                                        rule,
                                                        value,
                                                        callback,
                                                    ) => {
                                                        if (value === 0) {
                                                            callback(
                                                                "时长不能为0",
                                                            );
                                                        } else if (!value) {
                                                            callback(
                                                                "时长不能为空",
                                                            );
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                    trigger: "change",
                                                },
                                            ];
                                            this.setDefaultStringValue(
                                                targetObj,
                                            );
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "reasonLeave":
                                            item.reasonLeaveRequired = item.reasonLeaveRequired || 0;
                                            targetObj.key = arr[i];
                                            targetObj.compType = "reasonLeave";
                                            targetObj.type = "textarea";
                                            targetObj.label = "请假事由";
                                            targetObj.labelWidth = "90"
                                            targetObj.disabled = false;
                                            targetObj.maxLength = item.maxLength ?  item.maxLength : 50;

                                            targetObj.rules = [
                                                {
                                                    required:
                                                        item.reasonLeaveRequired ===
                                                        0
                                                            ? false
                                                            : true,
                                                    message: "请假类型不能为空",
                                                    trigger: ["blur", "change"],
                                                },
                                            ];
                                            targetObj.required =
                                                item.reasonLeaveRequired === 0
                                                    ? false
                                                    : true;
                                            this.setDefaultStringValue(
                                                targetObj,
                                            );
                                            this.ruleFormData.push(targetObj);
                                            break;
                                    }
                                }
                                break;
                            /**
                             * 换班套件 kitsShiftChange
                             * shiftChangeDate --换班时间
                             * shiftWorker --换班人
                             * shiftChangeReason --换班原因
                             * dormInfo --宿舍信息
                             */
                            case "kitsShiftChange":
                                let ar2 = [
                                    "shiftChangeDate",
                                    "shiftWorker",
                                    "shiftChangeReason",
                                    "dormInfo"
                                ]
                                let targetObj
                                for (let key of ar2) {
                                    targetObj = this._.cloneDeep(obj);
                                    switch (key) {
                                        case "shiftChangeDate":
                                            targetObj.key = key;
                                            targetObj.compType = "shiftChangeDate";
                                            targetObj.label = "换班时间";
                                            targetObj.labelWidth = "90"
                                            targetObj.placeholder = "请选择换班时间"
                                            targetObj.required = true
                                            targetObj.rules[0].required = true
                                            targetObj.type = "dateTime";
                                            targetObj.status = "dates";
                                            targetObj["value-format"] = "yyyy-MM-dd";
                                            targetObj["format"] = "yyyy-MM-dd";
                                            await this.setShiftChangeDatePickerOptions({comp: targetObj})
                                            this.setDefaultStringValue(targetObj);
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "shiftWorker":
                                            targetObj.key = key;
                                            targetObj.compType = "shiftWorker";
                                            targetObj.label = "换班人";
                                            targetObj.labelWidth = "90"
                                            targetObj.placeholder = "请选择换班人"
                                            targetObj.required = true
                                            targetObj.rules[0].required = true
                                            targetObj.type = "selectTree";
                                            targetObj.options = "1"
                                            targetObj.selectableRange = "1"
                                            this.setDefaultPeopleValue(targetObj);
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "shiftChangeReason":
                                            targetObj.key = key;
                                            targetObj.compType = "shiftChangeReason";
                                            targetObj.label = "换班原因";
                                            targetObj.labelWidth = "90"
                                            targetObj.placeholder = "请输入换班原因"
                                            targetObj.required = true
                                            targetObj.rules[0].required = true
                                            targetObj.type = "textarea";
                                            targetObj.disabled = false;
                                            targetObj.maxLength = 100;
                                            this.setDefaultStringValue(targetObj);
                                            this.ruleFormData.push(targetObj);
                                            break;
                                        case "dormInfo":
                                            targetObj.key = key;
                                            targetObj.compType = "dormInfo";
                                            targetObj.label = "宿舍信息";
                                            targetObj.labelWidth = "90"
                                            targetObj.required = false
                                            targetObj.rules[0].required = false
                                            targetObj.type = "dormInfo";
                                            this.setDefaultStringValue(targetObj);
                                            this.ruleFormData.push(targetObj);
                                            break;
                                    }
                                }
                                break;
                            /**
                             * 套件库
                             * 走读申请 kitsDayStudent
                             * applicant -- 申请人
                             * studentType -- 学生类型
                             * dayStudentStartDate -- 走读开始日期
                             * dayStudentEndDate --走读结束日期
                             * dayStudentReason -- 申请原因
                             * descriptionText -- 描述文字
                             * dayStudentAttachment -- 上传附件
                             * reasonLeave -- 请假事由
                             * */
                            case "kitsDayStudent":
                                console.log("kitsDayStudent",item)
                                let ar3 = [
                                    "applicant",
                                    "studentType",
                                    "dayStudentStartDate",
                                    "dayStudentEndDate",
                                    "dayStudentReason",
                                    "descriptionText",
                                    "attachmentUpload"
                                ]
                                let targetObj1
                                for (let key of ar3) {
                                    targetObj1 = this._.cloneDeep(obj);
                                    switch (key) {
                                        case "applicant":
                                            // item.reasonLeaveRequired = item.reasonLeaveRequired || 0;
                                            targetObj1.key = key;
                                            targetObj1.compType = 'applicant';
                                            targetObj1.type = "selectTreeStu";
                                            targetObj1.label = "申请人";
                                            targetObj1.labelWidth = "90"
                                            targetObj1.placeholder = "请选择申请人"
                                            this.setDefaultPeopleValue(targetObj1);
                                            this.ruleFormData.push(targetObj1);
                                            break;
                                        case "studentType":
                                            targetObj1.key = key;
                                            targetObj1.compType = "studentType";
                                            targetObj1.type = "select";
                                            targetObj1.ele = "el-select";
                                            targetObj1.label = "学生类型";
                                            targetObj1.placeholder = "请选择学生类型"
                                            targetObj1.labelWidth = "90"
                                            targetObj1.multiple = false;

                                            targetObj1.options = [
                                                {
                                                    value:0,
                                                    label:'住宿'
                                                },{
                                                    value:1,
                                                    label:'走读'
                                                },{
                                                    value:2,
                                                    label:'半读'
                                                }
                                            ]
                                            this.setDefaultStringValue(
                                                targetObj1,
                                                );
                                            this.ruleFormData.push(targetObj1);
                                            break;
                                        case "dayStudentStartDate":
                                            targetObj1.key = key;
                                            targetObj1.compType = "dayStudentStartDate";
                                            targetObj1.label = "开始日期";
                                            targetObj1.labelWidth = "90"
                                            targetObj1.placeholder = "走读开始日期"
                                            targetObj1.required = true
                                            targetObj1.rules[0].required = true
                                            targetObj1.type = "dateTime";
                                            targetObj1.status = "date";
                                            targetObj1["value-format"] = "yyyy-MM-dd";
                                            targetObj1["format"] = "yyyy-MM-dd";
                                            targetObj1.rules = [
                                                {
                                                    required: true,
                                                    validator:async (rule, value, callback) => {
                                                        // if(this.ruleForm.applicant.length>0 && this.ruleForm.dayStudentEndDate){
                                                        //     await this.checkoutDateIsReapt()
                                                        //     console.log('执行吗')
                                                        //     this.$refs.formRef.$refs.formRef.validateField('dayStudentEndDate', (errorMessage) => {
                                                        //         if (errorMessage) {
                                                        //         console.log('验证未通过: ', errorMessage);
                                                        //         } else {
                                                        //         console.log('验证通过');
                                                        //         }
                                                        //     });

                                                        // }else{
                                                        //     callback()
                                                        // }
                                                        if (value === "" || value == null) {
                                                            callback("开始日期不能为空");
                                                        } else {
                                                            callback()
                                                        }
                                                    },
                                                },
                                            ];
                                            this.setDefaultStringValue(targetObj1);
                                            this.ruleFormData.push(targetObj1);
                                            break;
                                        case "dayStudentEndDate":
                                            targetObj1.key = key;
                                            targetObj1.compType = "dayStudentEndDate";
                                            targetObj1.label = "结束日期";
                                            targetObj1.labelWidth = "90"
                                            targetObj1.placeholder = "走读结束日期"
                                            targetObj1.required = true

                                            // targetObj1.rules[0].required = true
                                            targetObj1.rules = [
                                                {
                                                    required: true,
                                                    validator: async (
                                                        rule,
                                                        value,
                                                        callback,
                                                    ) => {
                                                        console.log('targetObj1',this.ruleForm,this.assemblyFormData())
                                                        if(this.ruleForm.applicant.length>0){
                                                            await this.checkoutDateIsReapt()
                                                        }else{
                                                            callback()
                                                        }
                                                        if (value == '' || value == null) {
                                                            callback('结束日期不能为空')
                                                        } else{
                                                            if (this.isRepeate) {
                                                                console.log('--------this.tipContent1-----------',this.tipContent1)
                                                                callback(this.tipContent1)
                                                            }else {
                                                                callback()

                                                            }
                                                        }
                                                    },
                                                    trigger: "change",
                                                },
                                            ];

                                            targetObj1.type = "dateTime";
                                            targetObj1.status = "date";
                                            targetObj1["value-format"] = "yyyy-MM-dd";
                                            targetObj1["format"] = "yyyy-MM-dd";
                                            this.setDefaultStringValue(targetObj1);
                                            this.ruleFormData.push(targetObj1);
                                            break;
                                        case "dayStudentReason":
                                            targetObj1.key = key;
                                            targetObj1.compType = "dayStudentReason";
                                            targetObj1.label = "申请原因";
                                            targetObj1.labelWidth = "90"
                                            targetObj1.placeholder = "请输入申请原因"
                                            targetObj1.required = item.reasonRequired === 0? false: true;
                                            targetObj1.rules[0].required =  item.reasonRequired === 0? false: true;
                                            targetObj1.maxLength  = 200;
                                            targetObj1.type = "textarea";
                                            this.setDefaultStringValue(targetObj1);
                                            this.ruleFormData.push(targetObj1);
                                            break;
                                        case "attachmentUpload":
                                            targetObj1.key = key;
                                            targetObj1.compType = "attachmentUpload";
                                            targetObj1.label = "上传附件";
                                            targetObj1.labelWidth = "90"
                                            targetObj1.type = "attachmentUpload";
                                            targetObj1.few = 1
                                            targetObj1.fileSize = 10
                                            targetObj1.accept = item.accept
                                            // targetObj1.showTips = 'false'
                                            targetObj1.required = item.attachmentRequired === 1 ? true : false
                                            targetObj1.rules[0].required = item.attachmentRequired === 1 ? true : false
                                            if (_this.ruleFormInfoData[targetObj1.key]) {
                                                targetObj1.uploadValue = _this.ruleFormInfoData[targetObj1.key].displayValue;
                                                _this.$set(_this.ruleForm, targetObj1.key, _this.ruleFormInfoData[targetObj1.key].displayValue);
                                                // _this.ruleFormInfoData[targetObj1.key].value = _this.ruleFormInfoData[targetObj1.key].displayValue
                                            } else {
                                                targetObj1.uploadValue = [];
                                                _this.$set(_this.ruleForm, targetObj1.key, []);
                                            }
                                            // this.setDefaultArrayValue1(targetObj1);
                                            this.ruleFormData.push(targetObj1);
                                            break;
                                        case "descriptionText":
                                            targetObj1.key = key;
                                            targetObj1.type = 'descriptionText';
                                            targetObj1.compType = "descriptionText";
                                            targetObj1.labelWidth = "90"
                                            targetObj1.color = item.fontColor
                                            targetObj1.label = '';
                                            targetObj1.rules = []
                                            targetObj1.required = false
                                            targetObj1.fontSize = item.fontSize
                                            targetObj1.value = item.dayStudentComment
                                            console.log(this.ruleFormData,item.dayStudentComment,targetObj1.value,'targetObj1.value')
                                            this.setDefaultStringValue(targetObj1);
                                            this.ruleFormData.push(targetObj1);
                                            break;
                                    }
                                }
                                break;
                            default:
                                obj.key = item.id;
                                obj.type = item.compType;
                                this.setDefaultStringValue(obj);
                                break;
                        }

                        /**
                         * 套件库
                         * 请假/调休套件 kitsLeaveAbsence
                         * 换班套件 kitsShiftChange
                         * 走读申请套件 kitsDayStudent
                         * */
                        if (!["kitsLeaveAbsence", "kitsShiftChange",'kitsDayStudent'].includes(obj.key)) {
                            this.ruleFormData.push(obj);
                        }
                        // 不存在申请人组件时，不显示接送人管理
                        let find = this.ruleFormData.find(i => i.key === "pickDropPersons")
                        if (find) {
                            let index = this.ruleFormData.findIndex(i => i.key === "applicant")
                            find.showPickDropManage = index > -1;
                        }
                        this.ruleFormData = this.ruleFormData.filter(item => item.compType != 'kitsSpecialLeave')
                    }
                    //return;
                    // 修改
                    if (this.OAQueryData.dataTitle && this.OAQueryData.formId) {
                        // 获取接送人下拉数据
                        this.getParentList();
                        this.loadingTable = false;
                        this.$nextTick(async() => {
                            let data = this.ruleFormData.find(
                                (i) => i.key === "leaveType",
                            );
                            if (data) {
                                // 获取剩余调休时长
                                // await this.setTipsBasedOnLeaveType(data)

                                // 修改时间组件类型
                                this.changeLeaveTimeFormat(data);
                                // 检查请假类型是否存在
                                let isHaveLeaveType = await this.checkleaveType(data);
                                if (!isHaveLeaveType) {
                                    return
                                }
                                // 按半天请假把noonType赋上，展示请假时长查看明细按钮
                                if (this.ruleFormInfoData.leaveType && this.ruleFormInfoData.leaveType.displayValue && this.ruleFormInfoData.leaveType.displayValue.leaveUnit == '3') {
                                    this.$set(this.ruleForm, 'leaveStartTimeNoonType', this.ruleFormInfoData.leaveStartTime.noonType)
                                    this.$set(this.ruleForm, 'leaveEndTimeNoonType', this.ruleFormInfoData.leaveEndTime.noonType)
                                    if (this.ruleForm.leaveStartTime && this.ruleForm.leaveEndTime) {
                                        this.leaveDurationDetailsBtnShow = true
                                    } else {
                                        this.leaveDurationDetailsBtnShow = false
                                    }
                                }
                            }

                            // 获取流程
                            this.getProcess();
                        })

                    } else {
                        // 提交，没有必填项时，直接获取流程
                        const hasRequired =
                            this.formInfo.list.find((i) => i.required) !==
                            undefined;
                        if (!hasRequired) {
                            this.getProcess();
                        }
                    }
                    this.loadingTable = false;
                } else if (res.data && res.data.msg) {
                }
            });
            // 如果是修改
            if (this.OAQueryData.dataTitle && this.OAQueryData.formId) {
                let leaveTypeFormItem = this.ruleFormData.find(
                    (e) => e.compType === "leaveType",
                );
                if (leaveTypeFormItem) {
                    // 清空请假类型上一次的提示
                    this.isSubmitBtn = true;  // 能正常提交单子
                    this.isShowRepeateForm = false;
                    this.showBalance = false;
                    this.overLeaveTime = 0;
                    this.$set(leaveTypeFormItem, "tips", "")
                    let leaveStartTimeFormItem = this.ruleFormData.find(
                        (e) => e.compType === "leaveStartTime",
                    );
                    if (leaveStartTimeFormItem) {
                        this.$set(leaveStartTimeFormItem, "disabled", false);
                    }
                    let leaveEndTimeFormItem = this.ruleFormData.find(
                        (e) => e.compType === "leaveEndTime",
                    );
                    if (leaveEndTimeFormItem) {
                        this.$set(leaveEndTimeFormItem, "disabled", false);
                    }
                    // 在获取余额
                    await this.changeVacationLeaveType(leaveTypeFormItem);
                }
            }
        },
        setDefaultStringValue(obj) {
            if (this.ruleFormInfoData[obj.key]) {
                this.$set(
                    this.ruleForm,
                    obj.key,
                    this.ruleFormInfoData[obj.key].value,
                );
            } else {
                this.$set(this.ruleForm, obj.key, "");
            }
        },
        setDefaultArrayValue(obj) {
            if (
                this.ruleFormInfoData[obj.key] &&
                this.ruleFormInfoData[obj.key].value !== ""
            ) {
                this.$set(
                    this.ruleForm,
                    obj.key,
                    this.ruleFormInfoData[obj.key].value.split(","),
                );
            } else {
                this.$set(this.ruleForm, obj.key, []);
            }
        },
        setDefaultArrayValue1(obj) {
            console.log(this.ruleFormInfoData[obj.key],'this.ruleFormInfoData[obj.key]')
            if (
                this.ruleFormInfoData[obj.key] &&
                this.ruleFormInfoData[obj.key].value !== ""
            ) {
                let arr = []
                arr = this.ruleFormInfoData[obj.key].value.split('.')
                this.$set(
                    this.ruleForm,
                    obj.key,
                    arr,
                );
                console.log(this.ruleForm,this.ruleFormInfoData[obj.key].value,'value')
            } else {
                this.$set(this.ruleForm, obj.key, []);
            }
        },
        setDefaultPeopleValue(obj) {
            if (
                this.ruleFormInfoData[obj.key] &&
                this.ruleFormInfoData[obj.key].displayValue
            ) {
                this.$set(
                    this.ruleForm,
                    obj.key,
                    this.ruleFormInfoData[obj.key].displayValue,
                );
            } else {
                this.$set(this.ruleForm, obj.key, []);
            }
            console.log(obj,'拼装申请人')
        },
        setDefaultAddressValue(obj) {
            // 修改  数据回显
            if (
                this.ruleFormInfoData[obj.id] &&
                this.ruleFormInfoData[obj.id].value.length > 0
            ) {
                // ['省', '市', '县']
                // splice(0, 3) 过滤掉街道数据
                let codeList = this.ruleFormInfoData[obj.key].value
                    .map((i) => i.areaCode.toString())
                    .splice(0, 3);
                let nameList = this.ruleFormInfoData[obj.key].value
                    .map((i) => i.areaName)
                    .splice(0, 3);
                const index = obj.optionsData.findIndex(
                    (i) => i.value === codeList[0],
                );
                obj.optionsData[index].children.push({
                    // 市数据
                    label: nameList[1],
                    value: codeList[1].toString(),
                    children: [
                        {
                            // 县数据
                            label: nameList[2],
                            value: codeList[2].toString(),
                            leaf: "leaf",
                        },
                    ],
                });
                this.$set(this.ruleForm, obj.key, {
                    label: nameList,
                    value: codeList,
                });
            }
        },
        setDefaultRadioValue(obj) {
            if (this.ruleFormInfoData[obj.key]) {
                this.$set(
                    this.ruleForm,
                    obj.key,
                    this.ruleFormInfoData[obj.key].value,
                );
            } else {
                this.$set(this.ruleForm, obj.key, "");
            }
        },
        setDefaultMultiValue(obj) {
            if (
                this.ruleFormInfoData[obj.key] &&
                this.ruleFormInfoData[obj.key].value !== ""
            ) {
                // let data = this.ruleFormInfoData[obj.key].value
                //     .split(",")
                //     .map((i) => Number(i));
                // if (obj.guanLian) {
                //     obj.ids.forEach((item) => {
                //         if (data.find((i) => i === item.value)) {
                //             item.list.forEach((it) => {
                //                 let index = this.ruleFormData.findIndex(
                //                     (i) => it.id == i,
                //                 );
                //                 this.ruleFormData[index].show = true;
                //             });
                //         }
                //     });
                // }
                // this.$set(this.ruleForm, obj.key, data);
            } else {
                this.$set(this.ruleForm, obj.key, []);
            }
        },
        getSchoolProcessApplyList(obj) {
            this._fet("/oa/schoolProcessApply/approvalFormList", {
                schoolId: this.schoolId,
                formId: obj.associatedApprovalForm.join(","),
            }).then((res) => {
                this.handleRes(res, () => {
                    obj.options = res.data.data.map((i) => ({
                        value: i.id,
                        label: i.dataTitle,
                    }));
                });
            });
        },
        /**
         * @Description: 获取请假类型
         * @DoWhat: 获取请假类型，拼装数据
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-06 09:51:05
         */
        async getLeaveType() {
            return new Promise((resolve) => {
                const vacationManagementModel = new CoworkingCampusOAVacationManagementModel();
                // type: 1是员工2是学生
                const typeMap = {
                    "teacher": 1,
                    "student": 2
                };
                const KEY_VAL = '违纪';
                let type = 1;
                // applicant

                if (this.ruleForm.applicant && this.ruleForm.applicant.length > 0) {
                    // 仅申请人 = 1个人的时候，允许使用设置了余额的假期
                    if (this.ruleForm.applicant.length === 1) {

                        type = typeMap[this.ruleForm.applicant[0].type]
                    } else {   // 多个申请人的时候，不允许使用设置了余额的假期（在请假类型里面不展示设置了余额的假期）
                        const student = this.ruleForm.applicant.find(i => i.type === "student")
                        const teacher = this.ruleForm.applicant.find(i => i.type === "teacher")
                        if (student && teacher) {

                            resolve([])
                            return
                        } else {

                            type = typeMap[this.ruleForm.applicant[0].type]
                        }
                    }
                } else if (Object.prototype.hasOwnProperty.call(this.ruleFormInfoData, 'applicant')){
                    let val = this.ruleFormInfoData.applicant['value'] || [];
                    const student = val.find(i => i.type === "student")
                    const teacher = val.find(i => i.type === "teacher")

                    if (val.length > 0) {
                        if (student && teacher) {
                            resolve([])
                            return
                        } else {
                            type = typeMap[val[0].type];
                        }
                    } else {
                        type = 1
                    }
                }

                // 判断是不是衡中或者衡实，如果是衡中或者衡实就传值
                //
                let specials = this.specialHandling.map(item => {
                    return item.schoolId;
                });
                let formName = specials.includes(this.schoolId) ? this.formTitle : '';

                vacationManagementModel.getUndeletedList({
                    formName: formName,
                    schoolId: this.schoolId,
                    deleteStatus: "0",
                    type: type,
                    status: "1",//开启状态 1开启；0关闭
                }).then((res) => {
                    // 允许员工申请调休及所有假期的请假单
                    if (type == 1) {
                        // res.data.data = res.data.data.filter(e => e.vacationName.indexOf("调休") === -1)
                    } else {
                        res.data.data = res.data.data.filter(e => e.vacationName.indexOf("调休") === -1)
                    }
                    // 特殊请假的请假类型只允许选择以小时计算且不是调休的
                    if (this.formInfo.list.find(e => e.compType === "kitsSpecialLeave")) {
                        res.data.data = res.data.data.filter(e => e.leaveUnit === 2 && e.vacationName.indexOf("调休") === -1)
                    }
                    // 有多个申请人不允许选择按半天的假期
                    if (this.ruleForm.applicant && this.ruleForm.applicant.length > 1) {
                        res.data.data = res.data.data.filter(e => e.leaveUnit != 3)
                    }
                    // 有多个申请人不允许使用设置了余额的假期
                    if (this.ruleForm.applicant && this.ruleForm.applicant.length > 1) {
                        res.data.data = res.data.data.filter(e => e.balanceSwitch != 1)
                    }
                    // 没有选择申请人 并且 当前登录人是系统管理员,过滤掉设置了余额的假期
                    const adminTeacherId = this.ruleForm.applicant && this.ruleForm.applicant.length > 0
                        ? this.ruleForm.applicant[0].id
                        : this.$store.state.teacherId;
                    if (!adminTeacherId) {
                        res.data.data = res.data.data.filter(e => e.balanceSwitch != 1)
                    }

                    // 隐藏请假结束时间的学校过滤按半天的假期
                    if (specials.includes(this.schoolId)) {
                        res.data.data = res.data.data.filter(e => e.leaveUnit != 3)
                    }
                    this.useVacationList = res.data.data;
                    this.adminVacationList = this.useVacationList.filter(i => i.balanceSwitch != 1)


                    resolve(res.data.data.map((i) => ({
                        value: i.id,
                        label: i.vacationName,
                        leaveUnit: i.leaveUnit,
                    })))
                });
            })
        },
        // 特殊请假的请假类型
        async getSpecialLeaveType() {

            return new Promise((resolve) => {
                let applicant = this._.cloneDeep(this.ruleForm.applicant);
                if(applicant && applicant.find(item => item.type == 'student') && applicant.find(item => item.type == 'teacher')){//申请人中是否同时含有学生和老师
                    resolve([])
                }
                const vacationManagementModel = new CoworkingCampusOAVacationManagementModel();
                // type: 1是员工2是学生
                let type = 1
                if (this.ruleForm.applicant && this.ruleForm.applicant[0]) {
                    type = {
                        "teacher": 1,
                        "student": 2
                    }[this.ruleForm.applicant[0].type]
                }
                vacationManagementModel.getUndeletedList({
                    schoolId: this.schoolId,
                    deleteStatus: "0",
                    type: type,
                    status: "1",//开启状态 1开启；0关闭
                }).then((res) => {
                    // 过滤了设置了余额的假期
                    let filterRes = res.data.data.filter(iv => iv.leaveUnit == 2)
                    let newRes = filterRes.filter(iv => iv.balanceSwitch != 1)

                    resolve(newRes.map((i) => ({
                        value: i.id,
                        label: i.vacationName,
                        leaveUnit: i.leaveUnit,
                    })))
                });
            })
        },
        getParentList() {
            let index = this.ruleFormData.findIndex(
                (i) => i.compType === "pickDropPersons",
            );
            // 表单中不存在接送人字段
            if (index === -1) {
                return;
            }
            // 表单中不存在申请字段
            if (!this.ruleForm["applicant"]) {
                return;
            }
            let onlyCodes = this.ruleForm["applicant"].filter(
                (i) => i.key === "student",
            );
            // 申请人不存在学生直接返回
            if (onlyCodes.length === 0) {
                this.ruleFormData[index].options = [];
                return;
            }
            this.onlyCode = onlyCodes.map((i) => i.onlyCode).join(",") || onlyCodes.map((i) => i.id).join(",")
            const campusOAModel = new CampusOAModel();
            campusOAModel
                .schoolInterconnection({
                    onlyCode: this.onlyCode,
                    productId: sessionStorage.getItem('teacherProduct'),
                })
                .then((res) => {
                    this.handleRes(res, () => {
                        this.ruleFormData[index].options = res.data.data.map(
                            (i) => ({
                                value: i.id,
                                label: i.userName,
                            }),
                        );
                    });
                });
        },
        // 设置可选换班时间
        async setShiftChangeDatePickerOptions({comp, clear = false} = {}) {
            if (!comp) comp = this.ruleFormData.find(e => e.key === 'shiftChangeDate')
            if (!comp) return
            if (clear && this.ruleForm.shiftChangeDate) this.ruleForm.shiftChangeDate = [] // 清空已选中值

            let params = {
                schoolId: this.schoolId
            }
            if (this.ruleForm.applicant && this.ruleForm.applicant[0] && this.ruleForm.applicant[0].type === 'teacher') {
                params = {
                    teacherId: this.ruleForm.applicant[0].id,
                    schoolId: this.ruleForm.applicant[0].schoolId ? this.ruleForm.applicant[0].schoolId : this.schoolId
                }
            }

            let scheduleDates = []
            const campusOAModel = new CampusOAModel()
            let res = await campusOAModel.getShiftChangeDate(params)
            if (res.data.code === "200") {
                scheduleDates = res.data.data
            }
            comp.pickerOptions = {
                disabledDate: (time) => {
                    let year = time.getFullYear()
                    let month = time.getMonth() + 1
                    month = month < 10 ? ('0' + month) : month
                    let date = time.getDate()
                    date = date < 10 ? ('0' + date) : date
                    let dateFormatted = year + '-' + month + '-' + date
                    let dateNow = new Date()
                    dateNow = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate())
                    if (time.getTime() >= dateNow.getTime() && scheduleDates.includes(dateFormatted)) {
                        return false
                    } else {
                        return true
                    }
                }
            }
            this.$forceUpdate();
        },
        // 获取并设置宿舍信息
        async setDormInfo(comp) {
            if (!comp) comp = this.ruleFormData.find(e => e.key === 'dormInfo')
            if (!comp) return
            if (!this.ruleForm.shiftChangeDate || (this.ruleForm.shiftChangeDate && this.ruleForm.shiftChangeDate.length == 0)) {
                this.ruleForm.dormInfo = ''
                return
            }

            let params = {
                schoolId: this.schoolId,
                dateList: this.ruleForm.shiftChangeDate
            }
            if (this.ruleForm.applicant && this.ruleForm.applicant[0] && this.ruleForm.applicant[0].type === 'teacher') {
                params = {
                    teacherId: this.ruleForm.applicant[0].id,
                    schoolId: this.ruleForm.applicant[0].schoolId ? this.ruleForm.applicant[0].schoolId : this.schoolId,
                    dateList: this.ruleForm.shiftChangeDate
                }
            }

            const campusOAModel = new CampusOAModel()
            let res = await campusOAModel.getDormInfo(params)
            if (res.data.code === "200") {
                this.ruleForm.dormInfo = res.data.data
            } else {
                this.ruleForm.dormInfo = ''
            }
        },

        /*
            树形组件相关方法
            showFormTreeDialog      打开表单中弹窗组件
            showProcessTreeDialog   打开审批人弹窗组件
            closeTreeDialog         关闭弹窗组件
            setTreeData             保存弹窗选中数据
            filterTreeData          筛选人员弹窗数据
            getTreeData             获取所有人员树形数据
         */
        async showFormTreeDialog(data) {
            // 赋值已经选择的数据,
            this.selectObjData = this.ruleForm[data.key] || [];
            // 重置请求树形数据
            this.treeData.name = '';
            this.treeData.parentId = '';
            this.treeData.haveDatePermission = '1';
            this.selectOrganization(data.selectableRange);
            this.selectOrganizationStatus(data.options);
            // 请求树形数据
            await this.getTreeDataList();
            // 展开
            this.setTreeAuxiliaryData.key = data.key;
            this.dialogObj.title = `选择${data.label}`;
            this.dialogObj.dialogVisible = true;

        },
        async showFormTreeDialogStu(data) {
            console.log('showFormTreeDialogStu',data)
            // 赋值已经选择的数据
            this.selectObjData = this.ruleForm[data.key] || [];
            // 重置请求树形数据
            this.treeData.name = '';
            this.treeData.parentId = '';
            this.treeData.haveDatePermission = '1';
            data.options = data.isAllowMultipleApplicant == 1?'2':data.options
            this.selectOrganization(data.selectableRange);
            console.log(data.options)
            this.selectOrganizationStatus(data.options);
            // 请求树形数据
            await this.getTreeDataList1('list')
            // 展开
            this.setTreeAuxiliaryData.key = data.key;
            this.dialogObj1.title = `选择${data.label}`;
            this.dialogObj1.dialogVisible = true;
        },
        /**
         * @Description: 选择组织机构请求的类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-23 14:04:02
         */
        selectOrganization(selectableRange) {
            switch (selectableRange) {
                case "1":
                    // 教职工
                    this.treeData.type = this.facultyStaff.type;
                    this.treeData.organType = this.facultyStaff.organType;
                    break;
                case "2":
                    // 学生家长
                    this.treeData.type = this.studentParents.type;
                    this.treeData.organType = this.studentParents.organType;
                    break;
                case "3":
                    // 教职工及学生家长
                    this.treeData.type = this.staffParents.type;
                    this.treeData.organType = this.staffParents.organType;
                    break;
            }
        },
        /**
         * @Description: 选择组织机构是否为多选
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-23 14:04:34
         */
        selectOrganizationStatus(options) {
            // console.log('options',options)
            switch (options) {
                case "1":
                    this.multiple = false;
                    break;
                case "2":
                    console.log('多选')
                    this.multiple = true;
                    break;
                default:
                    break;
            }
        },
        async showProcessTreeDialog(data) {
            this.treeData.haveDatePermission = '0';
            switch (Number(data.processType)) {
                case 2:
                    this.assembliesPersonType = "reviewer";
                    this.selectObjData = data.value || [];
                    break;
                case 3:
                    let assembliesPerson = data.person.filter((item) => {
                        return item.id;
                    });
                    this.assembliesPersonType = "copied";
                    this.assembliesPerson = [];

                    assembliesPerson.map((item) => {
                        this.assembliesPerson.push(item.id);
                    });
                    this.selectObjData = [...data.value, ...assembliesPerson];
                    break;
                default:
                    break;
            }
            this.treeData.name = "";
            this.treeData.parentId = "";

            this.selectOrganization(data.rang);
            this.selectOrganizationStatus("2"); // 多选

            await this.getTreeDataList();

            this.setTreeAuxiliaryData.key = {};
            this.setTreeAuxiliaryData.key = {
                type: "process",
                designId: data.designId,
            };

            this.dialogObj.title = `选择${data.designName}`;
            this.dialogObj.key += 1;
            this.dialogObj.dialogVisible = true;
        },
        closeTreeDialog() {
            this.dialogObj.dialogVisible = false;
            this.dialogObj1.dialogVisible = false;
        },
        async setTreeData(data) {
            // 流程 抄送人和审批人
            if (data.key.designId && data.key.type === "process") {
                const ids = this.processUserContent[0].person.map((i) => i.id);
                const index = this.processUserContent.findIndex(
                    (i) => i.designId === data.key.designId,
                );
                this.processUserContent[index].value = data.data; // 去除审核人与抄送人一致的问题，去除代码 && !ids.includes(i.id)
            } else {
                // 表单申请人和人员
                this.$set(this.ruleForm, data.key, data.data);
                let index = this.ruleFormData.findIndex(
                    (i) => i.key === data.key,
                );
                let obj = JSON.parse(JSON.stringify(this.ruleFormData[index]));
                // 修改申请人之后获取接送人信息，如果有换班日期组件更新可选换班日期
                if (data.key === "applicant") {
                    if (this.ruleFormData.filter(item => item.compType == "specialLeave").length == 0) { //普通请假套件
                        // await this.changeEndTimeChose();
                    } else {
                        // 清空所有表单信息
                        this.$refs.formRef.$refs.specialLeave[0].clearLeaveForm()
                    }
                    this.ruleForm["pickDropPersons"] = "";
                    this.getParentList(data);
                    obj.required = true;
                    await this.setShiftChangeDatePickerOptions({clear: true}) // 设置可选换班日期
                    await this.setDormInfo() // 获取并设置宿舍信息
                }
                this.handleChange(obj);
            }
            // 关闭弹窗
            this.closeTreeDialog();
        },
        getTreeData(data) {
            return new Promise((resolve) => {
                const campusSafety = new CampusSafetyModel();
                campusSafety
                    .getIssueScopeByAccess({
                        schoolId: this.schoolId,
                        applyPerson: data,
                    })
                    .then((res) => {
                        resolve(res.data.data);
                    });
            });
        },
        async filterTreeData(type) {
            // 1教师数据 2学生数据 3全部数据
            switch (type) {
                case 1:
                    if (this.teacherTreeData.length === 0) {
                        this.teacherTreeData = await this.getTreeData(1);
                    }
                    return this.teacherTreeData;
                case 2:
                    if (this.studentTreeData.length === 0) {
                        this.studentTreeData = await this.getTreeData(2);
                    }
                    return this.studentTreeData;
                case 3:
                    if (this.allTreeData.length === 0) {
                        this.allTreeData = await this.getTreeData(3);
                    }
                    return this.allTreeData;
                default:
                    return [];
            }
        },
        // 单个请假组的时长获取
        changeDuration(data){
            this.ruleForm.specialLeaveTotalDuration = data.duration
            this.ruleForm.unit = data.unit;
            let leaveDurationFormItem = this.ruleFormData.find(
                (e) => e.compType === "specialLeave",
            );
            leaveDurationFormItem.unit = data.unit == 1 ? '天' : '小时';;
            this.dealRequiredForm();
        },
        /*
            获取表单流程
            handleChange   必填项修改
            getProcess     获取表单流程
            delProcessPeople      删除审批人/抄送人
            dealRelationForm      处理关联表单数据修改
            dealRequiredForm      处理必填项表单数据修改
            handleDateHalfChange 请假类型按半天选择时间
         */
        handleChange: debounce(
            async function (data) {
                console.log(data,'data--data')
                const functionMap = {
                    // 申请人
                    "applicant": async(data) => await this.handleApplicantChange(data),
                    // 请假类型
                    "leaveType": async(data) => await this.leaveTypeChange(data),
                    // 请假开始时间
                    "leaveStartTime": this.handleLeaveStartTimeChange,
                    // 请假结束时间
                    "leaveEndTime": this.handleLeaveEndTimeChange,
                }
                if (functionMap[data.key]) {
                    await functionMap[data.key](data)
                }
                if (
                    data.compType === "leaveType" &&
                    this.ruleForm["leaveType"]
                ) {
                    console.log('this.ruleForm["leaveType"]',this.ruleForm["leaveType"]);
                    // 先判断有无申请人
                    const hasTeacherId = this.ruleForm.applicant && this.ruleForm.applicant.length > 0
                        ? this.ruleForm.applicant[0].id
                        : this.$store.state.teacherId;
                    console.log('hasTeacherId',hasTeacherId,'这是上面+++++++++++++++++++++++');
                    if (!hasTeacherId) {
                        this.showProcess = false
                        this.isShowRepeateForm = false;
                        this.showBalance = false;
                        this.overLeaveTime = 0;
                        return
                    }
                    // 先把校验有审批中的调休单子字段 置空
                    this.isShowRepeateForm = false;
                    this.showBalance = false;
                    console.log('是否展示余额',this.showBalance);
                    await this.getHasOngiongApplicaion(data);
                    console.log('this.isShowRepeateForm是否有审批中调休的单子',this.isShowRepeateForm);
                    if (this.isShowRepeateForm) {
                        this.$refs.formRef.$refs.formRef.validateField("leaveType");
                        return
                    }
                    this.changeVacationLeaveType(data);
                    
                }
                if (
                    data.compType === "leaveStartTime" &&
                    this.ruleForm["leaveStartTime"]
                ) {
                    await this.changeEndTimeChose();
                    if (this.isRepeate) {
                        this.$refs.formRef.$refs.formRef.validate((valid) => {
                        //valid返回的是布尔值，所有都填写了为true，反之则为false
                        })
                        return
                    }
                    // 如果是修改
                    if (this.OAQueryData.dataTitle && this.OAQueryData.formId) {

                        let leaveTypeFormItem = this.ruleFormData.find(
                            (e) => e.compType === "leaveType",
                        );
                        if (leaveTypeFormItem) {
                            // 清空请假类型上一次的提示
                            this.isSubmitBtn = true;  // 先能正常提交单子
                            this.isShowRepeateForm = false;
                            this.showBalance = false;
                            this.overLeaveTime = 0;
                            this.$set(leaveTypeFormItem, "tips", "")
                            let leaveStartTimeFormItem = this.ruleFormData.find(
                                (e) => e.compType === "leaveStartTime",
                            );
                            if (leaveStartTimeFormItem) {
                                this.$set(leaveStartTimeFormItem, "disabled", false);
                            }
                            let leaveEndTimeFormItem = this.ruleFormData.find(
                                (e) => e.compType === "leaveEndTime",
                            );
                            if (leaveEndTimeFormItem) {
                                this.$set(leaveEndTimeFormItem, "disabled", false);
                            }
                            // 在获取余额
                            this.changeVacationLeaveType(leaveTypeFormItem);
                        }
                    }
                }
                if (
                    data.compType === "leaveEndTime" &&
                    this.ruleForm["leaveEndTime"]
                ) {
                    await this.changeEndTimeChose();
                    if (this.isRepeate) {
                        this.$refs.formRef.$refs.formRef.validate((valid) => {
                        //valid返回的是布尔值，所有都填写了为true，反之则为false
                        })
                        return
                    }
                    // 如果是修改
                    if (this.OAQueryData.dataTitle && this.OAQueryData.formId) {

                        let leaveTypeFormItem = this.ruleFormData.find(
                            (e) => e.compType === "leaveType",
                        );
                        if (leaveTypeFormItem) {
                            // 清空请假类型上一次的提示
                            this.isSubmitBtn = true;  // 能正常提交单子
                            this.isShowRepeateForm = false;
                            this.showBalance = false;
                            this.overLeaveTime = 0;
                            this.$set(leaveTypeFormItem, "tips", "")
                            let leaveStartTimeFormItem = this.ruleFormData.find(
                                (e) => e.compType === "leaveStartTime",
                            );
                            if (leaveStartTimeFormItem) {
                                this.$set(leaveStartTimeFormItem, "disabled", false);
                            }
                            let leaveEndTimeFormItem = this.ruleFormData.find(
                                (e) => e.compType === "leaveEndTime",
                            );
                            if (leaveEndTimeFormItem) {
                                this.$set(leaveEndTimeFormItem, "disabled", false);
                            }
                            // 在获取余额
                            this.changeVacationLeaveType(leaveTypeFormItem);
                        }
                    }
                }
                // dayStudentStartDate dayStudentEndDate
                console.log(this.ruleForm["applicant"],this.ruleForm,'this.ruleForm["applicant"]')
                if (
                    data.compType === "dayStudentStartDate" &&
                    this.ruleForm["dayStudentStartDate"]  && this.ruleForm["dayStudentEndDate"] && this.ruleForm["applicant"].length>0
                ) {
                    await this.checkoutDateIsReapt();
                    console.log('this.isRepeate',this.isRepeate)
                    // if (this.isRepeate) {
                        console.log(this.isRepeate)
                        this.$refs.formRef.$refs.formRef.validate((valid) => {
                        console.log(valid,'vaaaaa')
                        //valid返回的是布尔值，所有都填写了为true，反之则为false
                        })
                        return
                    // }
                }
                // if (
                //     data.compType === "dayStudentEndDate" &&
                //     this.ruleForm["dayStudentEndDate"] && this.ruleForm["dayStudentStartDate"]  && this.ruleForm["applicant"].length>0
                // ) {
                //     await this.checkoutDateIsReapt();
                //     // console.log(this.isRepeate,'this.isRepeate')
                //     // if (this.isRepeate) {
                //     //     this.$refs.formRef.$refs.formRef.validate((valid) => {
                //     //     //valid返回的是布尔值，所有都填写了为true，反之则为false
                //     //     })
                //     //     return
                //     // }
                // }
                // 换班时间修改后更新宿舍信息
                if (data.key == "shiftChangeDate") {
                    this.setDormInfo()
                }

                // 选择教师时,不显示接送人管理
                if (data.key === "applicant") {
                    let find = this.ruleFormData.find(i => i.key === "pickDropPersons")
                    if (find) {
                        find.showPickDropManage = this.ruleForm.applicant.findIndex(i => i.key === "teacher") === -1
                    }
                }

                /**
                 * 特殊处理
                 * 311/222 衡水中学/衡实特殊处理开始时间和结束时间
                 * leaveStartTime 为开始时间， 同步到结束时间
                 *
                 * */
                let specials = this.specialHandling.map(item => {
                    return item.schoolId;
                });
                 if(specials.includes(this.schoolId)) {
                    if (data.compType === 'leaveStartTime') {
                        this.ruleForm['leaveEndTime'] = this.ruleForm['leaveStartTime'];
                        this.ruleForm['leaveEndTime'] = this.ruleForm['leaveEndTime'].split(' ')[0] + ' ' + '23:59:59';
                    }
                }

                if (data.required) {
                    // 获取特定流程
                    if(data.compType != 'reasonLeave'){
                        this.accessToSpecificProcesses();
                    }

                    this.dealRequiredForm(); // 必填项修改
                }


                console.log(this.ruleForm,'this.ruleForm')
            },
            300,
            true,
        ),
        // 处理修改申请人
        async handleApplicantChange() {
            console.log('处理',this.ruleFormData)
            let leaveTypeFormItem = this.ruleFormData.find(
                (e) => e.compType === "leaveType",
            );
            let dayStudentFormItem =  this.ruleFormData.find(
                (e) => e.compType === "dayStudentEndDate",
            );
            if (leaveTypeFormItem) {
                // 先把校验有审批中的调休单子字段,和获取余额提示 置空
                this.isShowRepeateForm = false;
                this.showBalance = false;
                this.overLeaveTime = 0;
                this.$set(leaveTypeFormItem, "tips", "")
                let leaveStartTimeFormItem = this.ruleFormData.find(
                    (e) => e.compType === "leaveStartTime",
                );
                if (leaveStartTimeFormItem) {
                    this.$set(leaveStartTimeFormItem, "disabled", false);
                }
                let leaveEndTimeFormItem = this.ruleFormData.find(
                    (e) => e.compType === "leaveEndTime",
                );
                if (leaveEndTimeFormItem) {
                    this.$set(leaveEndTimeFormItem, "disabled", false);
                }

                this.ruleForm.leaveType = "";
                if(this.ruleFormData.filter(item => item.compType == "specialLeave").length == 0){//普通请假套件
                    leaveTypeFormItem.options = await this.getLeaveType();
                    await this.handleLeaveTypeChange(leaveTypeFormItem)
                } else{//特殊请假套件
                    leaveTypeFormItem.options = await this.getSpecialLeaveType()
                    await this.handleLeaveTypeChangeSpecial(leaveTypeFormItem);
                    this.$refs.formRef.$refs.specialLeave[0].clearLeaveForm()
                }
                this.$nextTick(() => {
                    this.$refs.formRef.$refs.formRef.validateField("leaveType");
                })
            }
            // console.log(this.ruleFormData.filter(item => item.compType == "dayStudentStartDate").length )
            if(dayStudentFormItem){
                if(this.ruleFormData.filter(item => item.compType == "dayStudentStartDate").length>0){
                    // 走读申请套件，选择申请人清空开始、结束日期
                    this.clearDayStydentTime()
                }
            }
        },
        async leaveTypeChange(data){
            let leaveTypeFormItem1 = this.ruleFormData.find(
                (e) => e.compType === "specialLeave",
            );
            if (leaveTypeFormItem1) {
                await this.handleLeaveTypeChangeSpecial(data)
            }else{
                await this.handleLeaveTypeChange(data)
            }
        },
        // 处理修改请假套件请假类型
        async handleLeaveTypeChange(data) {
            // console.log("[handleLeaveTypeChange]data:", data, "this.ruleForm:", this._.cloneDeep(this.ruleForm))
            // 清空请假开始时间、请假结束时间及请假时长
            this.ruleForm.leaveStartTime = "";
            this.ruleForm.leaveEndTime = "";
            this.ruleForm.leaveDuration = "";
            this.ruleForm.reasonLeave = '';
            // 请假类型为设置了余额的假期，获取剩余时长
            // await this.changeVacationLeaveType(data)
            // 修改请假开始时间请假结束时间格式
            this.changeLeaveTimeFormat(data);
            // 清空请假时长计算
            let leaveDurationFormItem = this.ruleFormData.find(
                (e) => e.compType === "leaveDuration",
            );
            if (leaveDurationFormItem) {
                leaveDurationFormItem.unit = "";
            }
            // console.log("[handleLeaveTypeChange]this.ruleForm:", this._.cloneDeep(this.ruleForm))
        },
        async handleLeaveTypeChangeSpecial(data) {
            // 清空请假开始时间、请假结束时间及请假时长
            this.$refs.formRef.$refs.specialLeave[0].clearLeaveForm()
            this.ruleForm.specialLeaveTotalDuration = "";
            this.ruleFormData.forEach(item => {
                if(item.compType == 'specialLeave') {
                    item.vacationId = this.ruleForm.leaveType
                }
            })
            // 请假类型为调休时，获取剩余调休时长
            await this.setTipsBasedOnLeaveType(data)
        },
        async setTipsBasedOnLeaveType(data) {
            // 初始化提示信息和调休数据
            this.$set(data, "tips", "")
            this.cLeaveDay = this.$options.data.call(this).cLeaveDay
            // 获取请假类型名称
            let options = data.options.find((i) => i.value === this.ruleForm.leaveType)
            this.cLeaveDay.name = options ? options.label : ""
            if (this.cLeaveDay.name.includes("调休")) {
                const campusOAModel = new CampusOAModel()
                // 根据申请人或从存储中获取教师ID
                const teacherId = this.ruleForm.applicant && this.ruleForm.applicant.length > 0
                    ? this.ruleForm.applicant[0].id
                    : this.$store.state.teacherId;
                // 获取调休数据
                let res = await campusOAModel.getStaffCompensatoryLeaveDay({
                    vacationId: this.ruleForm.leaveType,
                    teacherId: teacherId,
                    dateTime: parseTime(new Date(), "y-m-d"),
                    startTime: this.ruleForm.leaveStartTime,
                    endTime: this.ruleForm.leaveEndTime
                })
                if (res.data && res.data.code === "200") {
                    // 设置剩余调休时长和请假单位
                    const { residue, unit, cycle } = res.data.data
                    this.cLeaveDay.residue = residue
                    this.cLeaveDay.unit = unit
                    if ([1, 2, 3].includes(unit)) {
                        options.leaveUnit = unit
                        const unitName = {
                            1: "天",
                            2: "小时",
                            3: "天"
                        }[+unit] || ""
                        const cycleName = {
                            1: "本周",
                            2: "本月",
                            3: "本年",
                        }[+cycle] || ""
                        // 设置请假类型表单项的提示信息
                        this.$set(data, "tips", `${cycleName}剩余调休余额${residue}${unitName}`)
                    }
                }
            }
            // 验证 leaveType 表单字段
            this.$refs.formRef.$refs.formRef.validateField("leaveType");
        },
        // 处理修改请假套件开始时间
        handleLeaveStartTimeChange(data) {
            if (!this.ruleForm["leaveStartTime"]) return
            const startTimeMap = {
                "date": this.ruleForm["leaveStartTime"].slice(0, 10) + " 00:00:00",
                "datetime": this.ruleForm["leaveStartTime"].slice(0, 16) + ":00"
            }
            this.ruleForm["leaveStartTime"] = startTimeMap[data.status];
            // 如果有固定时间，并且填写的时分不满足要求，则设置时、分为固定时间的第一个
            if(data.fixedTime && data.fixedTime['on-off'] == '1'){
                let time = this.ruleForm["leaveStartTime"];
                // 截取时分
                let hour = time.slice(11, 13);
                let minute = time.slice(14, 16);
                // 获取当前时、分
                let curHourAndMinuteTime = `${hour}:${minute}`;
                // 获取固定时间列表
                let fixedTimeList = data.fixedTime.timeSetting.map(item => item.time);
                if (fixedTimeList.includes(curHourAndMinuteTime)) {  
                    this.ruleForm["leaveStartTime"] = time.slice(0, 10) + " " + curHourAndMinuteTime + ":00";
                } else {
                    this.ruleForm["leaveStartTime"] = time.slice(0, 10) + " " + fixedTimeList[0] + ":00";
                }
            }
        },
        // 处理修改请假套件结束时间
        handleLeaveEndTimeChange(data) {
            if (!this.ruleForm["leaveEndTime"]) return
            const endTimeMap = {
                "date": this.ruleForm["leaveEndTime"].slice(0, 10) + " 23:59:59",
                "datetime": this.ruleForm["leaveEndTime"].slice(0, 16) + ":59"
            }
            this.ruleForm["leaveEndTime"] = endTimeMap[data.status];
            // 如果有固定时间，并且填写的时分不满足要求，则设置时、分为固定时间的第一个
            if(data.fixedTime && data.fixedTime['on-off'] == '1'){
                let time = this.ruleForm["leaveEndTime"];
                // 截取时分
                let hour = time.slice(11, 13);
                let minute = time.slice(14, 16);
                // 获取当前时、分
                let curHourAndMinuteTime = `${hour}:${minute}`;
                // 获取固定时间列表
                let fixedTimeList = data.fixedTime.timeSetting.map(item => item.time);
                if (fixedTimeList.includes(curHourAndMinuteTime)) {  
                    this.ruleForm["leaveEndTime"] = time.slice(0, 10) + " " + curHourAndMinuteTime + ":59";
                } else {
                    this.ruleForm["leaveEndTime"] = time.slice(0, 10) + " " + fixedTimeList[0] + ":59";
                }
            }
        },
        // 处理走读申请套件选择申请人
        clearDayStydentTime(){
            this.ruleForm.dayStudentStartDate = "";
            this.ruleForm.dayStudentEndDate = "";
        },
        changeLeaveTimeFormat(data) {
            let status = "",
                format = "",
                valueFormat = "";
            // 获取到当前选中请假类型单位
            const leaveTypeOption = data.options.find(
                (e) => e.value === this.ruleForm.leaveType,
            );
            // console.log("[changeLeaveTimeFormat]leaveTypeOption:", leaveTypeOption)
            if (leaveTypeOption) {
                const statusMap = {
                    1: "date",
                    2: "datetime",
                    3: "dateHalf"
                }
                status = statusMap[leaveTypeOption.leaveUnit];
                format =
                    leaveTypeOption.leaveUnit === 1
                        ? "yyyy-MM-dd"
                        : "yyyy-MM-dd HH:mm";
                valueFormat =
                    leaveTypeOption.leaveUnit === 1
                        ? "yyyy-MM-dd"
                        : "yyyy-MM-dd HH:mm:ss";
                // 按半天额外设置变量存储上午下午，其他放假删除存储上午下午的变量
                // console.log("[changeLeaveTimeFormat]status:", status)
                if (status === "dateHalf") {
                    this.$set(this.ruleForm, "leaveStartTimeNoonType", "1")
                    this.$set(this.ruleForm, "leaveEndTimeNoonType", "1")
                } else {
                    if (this.ruleForm.hasOwnProperty('leaveStartTimeNoonType')) {
                        delete this.ruleForm.leaveStartTimeNoonType
                    }
                    if (this.ruleForm.hasOwnProperty('leaveEndTimeNoonType')) {
                        delete this.ruleForm.leaveEndTimeNoonType
                    }
                }
                // 修改请假开始时间请假结束时间格式
                const compTypeList = ["leaveStartTime", "leaveEndTime"];
                this.ruleFormData.forEach((item) => {
                    if (compTypeList.includes(item.compType)) {
                        item["status"] = status;
                        item["value-format"] = valueFormat;
                        item["format"] = format;
                        item["default-time"] =
                            item.compType === "leaveStartTime"
                                ? "00:00:00"
                                : "23:59:59";
                    }
                });
            } else {
                if (this.ruleForm.hasOwnProperty('leaveStartTimeNoonType')) {
                    this.ruleForm.leaveStartTimeNoonType = ""
                }
                if (this.ruleForm.hasOwnProperty('leaveEndTimeNoonType')) {
                    this.ruleForm.leaveEndTimeNoonType = ""
                }
            }
        },
        async dealRequiredForm() {
            // 所有必填项存在且表单校验通过,调取接口获取流程
            let requiredData = this.ruleFormData.filter((i) => {
                return (
                    i.required &&
                    (this.ruleForm[i.key] === null ||
                        this.ruleForm[i.key] === undefined ||
                        this.ruleForm[i.key].toString() === "")
                        && i.key != "kitsSpecialLeave"
                );
            });
            console.log('所有必填项',requiredData)
            this.speciLeaveType = this.ruleForm.leaveType;
            let checkTimeRange = true;
            if(this.ruleFormData.filter(item => item.compType == 'specialLeave').length > 0){
                checkTimeRange = await this.$refs.formRef.$refs.specialLeave[0].checkLeaveForm()
            }
            console.log(this.ruleFormData,'ruleFormData校验')
            console.log(requiredData.length === 0, checkTimeRange)
            // 必填项都有内容
            console.log('ifwai',requiredData.length)
            if (requiredData.length === 0 && checkTimeRange) {
                if (this.isSubmitBtn) {
                    console.log('-----',requiredData.length)
                    this.$refs.formRef.$refs.formRef.validate((val, rule) => {
                        // 表单校验通过
                        if (val) {
                            if (
                                this.ruleForm.returnTime &&
                                this.ruleForm.departureTime &&
                                this.ruleForm.departureTime >
                                    this.ruleForm.returnTime
                            ) {

                                let departureTimeLabel = this.ruleFormData.find(
                                    (i) => i.key === "departureTime",
                                ).label;
                                let returnTimeLabel = this.ruleFormData.find(
                                    (i) => i.key === "returnTime",
                                ).label;
                                return this.$message.warning(
                                    `${returnTimeLabel}必须大于${departureTimeLabel}`,
                                );
                            }
                            this.getProcess();
                        }
                    });
                } else {
                    this.showProcess = false;
                }
            } else {
                this.showProcess = false;
                this.processTips = "必填信息填写后，流程将自动显示";
            }
        },
        // 请假类型按半天选择时间
        async handleDateHalfChange(data) {
            console.log("[handleDateHalfChange]data:", data)
            let { comp } = data
            if (
                ["leaveStartTime", "leaveEndTime"].includes(comp.key)
                && this.ruleForm.leaveStartTime
                && this.ruleForm.leaveStartTimeNoonType
                && this.ruleForm.leaveEndTime
                && this.ruleForm.leaveEndTimeNoonType
            ) {
                let leaveStartTimestamp = new Date(this.ruleForm.leaveStartTime.split(" ")[0]).getTime()
                let leaveEndTimestamp = new Date(this.ruleForm.leaveEndTime.split(" ")[0]).getTime()
                if (
                    leaveStartTimestamp > leaveEndTimestamp
                    || (leaveStartTimestamp === leaveEndTimestamp && this.ruleForm.leaveStartTimeNoonType === "2" && this.ruleForm.leaveEndTimeNoonType === "1")
                ) {
                    this.$message.error("请假开始时间应小于请假结束时间")
                    this.showProcess = false
                    this.leaveDurationDetailsBtnShow = false
                    this.ruleForm.leaveDuration = ''
                    return
                }
                const campusOAModel = new CampusOAModel();
                let applyTeacherId = this.$store.state.teacherId
                if (this.ruleForm.applicant && this.ruleForm.applicant.length > 0) {
                    applyTeacherId = this.ruleForm.applicant[0].id
                }
                if (!applyTeacherId) {
                    this.$message.error("当前账户未绑定教师")
                    this.showProcess = false
                    this.leaveDurationDetailsBtnShow = false
                    this.ruleForm.leaveDuration = ''
                    return
                }
                // 查询实际请假时间
                let realTimeRes = await campusOAModel.getHalfDayRealTime({
                    applyTeacherId: applyTeacherId,
                    startTimeStr: this.ruleForm.leaveStartTime.split(" ")[0],
                    startType: this.ruleForm.leaveStartTimeNoonType,
                    endTimeStr: this.ruleForm.leaveEndTime.split(" ")[0],
                    endType: this.ruleForm.leaveEndTimeNoonType
                })
                console.log("[handleDateHalfChange]realTimeRes:", realTimeRes)
                if (realTimeRes && realTimeRes.data && realTimeRes.data.code == "200") {
                    let isRestDay = false
                    if (realTimeRes.data.data.hasOwnProperty('startTime') && realTimeRes.data.data.startTime) {
                        this.ruleForm.leaveStartTime = realTimeRes.data.data.startTime
                    } else {
                        isRestDay = true
                        this.ruleForm.leaveStartTime = ""
                    }
                    this.ruleForm.leaveStartTimeNoonType = realTimeRes.data.data.startType
                    if (realTimeRes.data.data.hasOwnProperty('endTime') && realTimeRes.data.data.endTime) {
                        this.ruleForm.leaveEndTime = realTimeRes.data.data.endTime
                    } else {
                        isRestDay = true
                        this.ruleForm.leaveEndTime = ""
                    }
                    this.ruleForm.leaveEndTimeNoonType = realTimeRes.data.data.endType
                    console.log("[handleDateHalfChange]this.ruleForm:", this.ruleForm)
                    if (isRestDay) {
                        this.$message.warning("请勿选择休息时间")
                        this.showProcess = false
                        this.leaveDurationDetailsBtnShow = false
                        this.ruleForm.leaveDuration = ''
                        return
                    }
                    // 校验是否允许请半天假
                    let checkAllowRes = await campusOAModel.checkAllowHalfDayLeave({
                        applyTeacherId: applyTeacherId,
                        startTime: this.ruleForm.leaveStartTime,
                        endTime: this.ruleForm.leaveEndTime
                    })
                    console.log("[handleDateHalfChange]checkAllowRes:", checkAllowRes)
                    if (checkAllowRes && checkAllowRes.data && checkAllowRes.data.code == "200") {
                        this.leaveDurationDetailsBtnShow = true
                        this.accessToSpecificProcesses(); // 获取请假时长
                        // 获取请假时长后校验重复请假单再获取流程
                        await this.changeEndTimeChose();
                        if (this.isRepeate) {
                            this.$refs.formRef.$refs.formRef.validate((valid) => {
                            //valid返回的是布尔值，所有都填写了为true，反之则为false
                            })
                            return
                        }
                        this.dealRequiredForm(); // 必填项修改
                    } else {
                        this.showProcess = false
                        this.leaveDurationDetailsBtnShow = false
                        this.ruleForm.leaveDuration = ''
                        let errorMsg = "校验是否允许请半天假失败"
                        if (checkAllowRes && checkAllowRes.data && checkAllowRes.data.msg) {
                            errorMsg = checkAllowRes.data.msg
                        }
                        this.$message.error(errorMsg)
                    }
                } else {
                    this.showProcess = false
                    this.leaveDurationDetailsBtnShow = false
                    this.ruleForm.leaveStartTime = this.ruleForm.leaveStartTime.split(" ")[0]
                    this.ruleForm.leaveEndTime = this.ruleForm.leaveEndTime.split(" ")[0]
                    this.ruleForm.leaveDuration = ''
                    let errorMsg = "获取实际请假时间失败"
                    if (realTimeRes && realTimeRes.data && realTimeRes.data.msg) {
                        errorMsg = realTimeRes.data.msg
                    }
                    this.$message.error(errorMsg)
                }
            }
        },
        // 请假时长明细弹窗打开
        handleLeaveDurationDetailsShow() {
            let startTimeStr = this.ruleForm.leaveStartTime.split(" ")[0]
            let startType = this.ruleForm.leaveStartTimeNoonType
            let endTimeStr = this.ruleForm.leaveEndTime.split(" ")[0]
            let endType = this.ruleForm.leaveEndTimeNoonType

            let startTime = new Date(startTimeStr).getTime()
            let endTime = new Date(endTimeStr).getTime()
            if (startTime > endTime || (startTime === endTime && startType === "2" && endType === "1")) {
                this.$toast("请假开始时间应小于请假结束时间")
                return
            }

            let applyTeacherId = this.ruleForm.applicant && this.ruleForm.applicant.length > 0 ? this.ruleForm.applicant[0].id : (this.applyTeacherId ? this.applyTeacherId : this.$store.state.teacherId);
            this.leaveDurationDetailsDialogObj.data = {
                applyTeacherId,
                startTimeStr,
                startType,
                endTimeStr,
                endType
            }
            this.leaveDurationDetailsDialogObj.dialogVisible = true;
        },
        // 请假时长明细弹窗关闭
        handleLeaveDurationDetailsClose() {
            this.leaveDurationDetailsDialogObj.dialogVisible = false;
        },
        /**
         * @Description: 获取特定流程
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-07 09:12:06
         */
        accessToSpecificProcesses() {
            // 获取套件库 时长
            let _this = this;
            _this.ruleForm.leaveDuration = "";

            let obj = {
                leaveType: function () {
                    const APPLICANT = "applicant";

                    let leaveStatus = true,
                        applicantStatus = true;
                    let specificValues = [
                        "leaveType",
                        "leaveStartTime",
                        "leaveEndTime",
                    ];
                    let leaveUnit = ""
                    let leaveTypeFormItem = _this.ruleFormData.find(e => e.key === "leaveType")
                    // console.log("[accessToSpecificProcesses]leaveTypeFormItem:", leaveTypeFormItem)
                    if (leaveTypeFormItem && _this.ruleForm.leaveType) {
                        let leaveTypeOptionSelected = leaveTypeFormItem.options.find((e) => e.value === _this.ruleForm.leaveType)
                        if (leaveTypeOptionSelected) {
                            leaveUnit = leaveTypeOptionSelected.leaveUnit
                        }
                    }
                    let reqObj = {
                        schoolId: _this.schoolId,
                        vacationId: "",
                        startTime: "",
                        startType: leaveUnit == 3 ? _this.ruleForm.leaveStartTimeNoonType : "",
                        endTime: "",
                        endType: leaveUnit == 3 ? _this.ruleForm.leaveEndTimeNoonType : "",
                        applicant: {
                            displayValue: [],
                            label: "",
                            type: "",
                            value: [
                                {
                                    id: "",
                                    type: "",
                                },
                            ],
                        },
                    };

                    for (var key in _this.ruleForm) {
                        // if (key == APPLICANT && _this.ruleForm[APPLICANT].length == 0) {
                        //     _this.$message.info('请先选择申请人～')
                        //     applicantStatus = false;
                        //     return;
                        // }

                        if (
                            key == APPLICANT &&
                            _this.ruleForm[APPLICANT].length > 0
                        ) {
                            let valData = JSON.parse(
                                JSON.stringify(_this.ruleForm[APPLICANT]),
                            ).filter((i) => i.key !== "organ")[0];
                            delete valData.children;
                            reqObj.applicant.displayValue = valData;
                            reqObj.applicant.label = "申请人";
                            reqObj.applicant.type = "applicant";
                            reqObj.applicant.value[0].id = valData.id;
                            reqObj.applicant.value[0].type = valData.key;
                        }
                    }

                    Object.keys(_this.ruleForm).forEach((key) => {
                        specificValues.forEach((item) => {
                            if (!_this.ruleForm[item]) {
                                leaveStatus = false;
                            }
                        });
                    });

                    if (leaveStatus && applicantStatus) {
                        // 还没有写比较时间
                        reqObj.vacationId = _this.ruleForm.leaveType;
                        reqObj.startTime = _this.ruleForm.leaveStartTime;
                        reqObj.endTime = _this.ruleForm.leaveEndTime;
                        reqObj.applicant = {};
                        if (
                            _this.ruleForm.applicant &&
                            _this.ruleForm.applicant.length > 0
                        ) {
                            reqObj.applicant = {
                                label: "申请人",
                                type: "applicant",
                                value: _this.ruleForm.applicant.map((e) => {
                                    return {
                                        id: e.id,
                                        type: e.key || e.type,
                                        key: e.key || e.type,
                                    };
                                }),
                            };
                        } else {
                            reqObj.applicant = {
                                label: "申请人",
                                type: "applicant",
                                value: [{
                                    id: _this.$store.state.teacherId,
                                    type: "teacher",
                                    key: "teacher",
                                }]
                            };
                        }

                        const coworkingCampusOAVacationManagementModel =
                            new CoworkingCampusOAVacationManagementModel();
                        coworkingCampusOAVacationManagementModel
                            .getVacationDuration(reqObj)
                            .then((res) => {
                                if (res.data.code == "200") {
                                    _this.vacationDuration = res.data.data

                                    let leaveDurationFormItem =
                                        _this.ruleFormData.find(
                                            (e) => e.key == "leaveDuration",
                                        );
                                    if (leaveDurationFormItem) {
                                        leaveDurationFormItem.unit = res.data.data.leaveUnit == "2" ? "小时" : "天";
                                        _this.ruleForm.leaveDuration =
                                            res.data.data.duration;
                                        _this.dealRequiredForm();
                                    }
                                } else {
                                    let msg = "获取请假时长失败";
                                    if (res.data.msg) {
                                        msg += "：" + res.data.msg;
                                    }
                                    _this.$message.error(msg);
                                }
                            });
                    }
                },
            };

            this.ruleFormData.forEach((item) => {
                obj[item.compType] ? obj[item.compType]() : "";
            });
        },
        getProcess: debounce( function () {
            let obj = {
                schoolId: this.schoolId,
                formId: this.formInfo.id,
                applyContent: this.assemblyFormData(),
            };
            const campusOA = new CampusOAModel();
            campusOA.getProcess(obj).then((res) => {
                this.handleRes(res, () => {
                    if (res.data.data instanceof Array) {
                        // 如果开启了换班人审核，流程中增加换班人审核
                        if (this.ruleForm.shiftWorker) {
                            let shiftWorkerComp = this.ruleFormData.find(e => e.key === 'shiftWorker')
                            if (shiftWorkerComp && shiftWorkerComp.shiftWorkerAudit === 1) {
                                let shiftWorker = this.ruleForm.shiftWorker[0]
                                res.data.data.push({
                                    approveMode: "1",
                                    designId: "shiftWorkerAudit",
                                    designName: "换班人",
                                    person: [{
                                        disabled: true,
                                        headImg: shiftWorker.headImg,
                                        id: shiftWorker.id,
                                        name: shiftWorker.name
                                    }]
                                })
                            }
                        }
                        // 展示流程
                        res.data.data.forEach((item) => {
                            item.value = [];
                        });
                        this.processUserContent = res.data.data;
                        this.$nextTick(() => {
                            this.showProcess = true;
                        });
                    } else {
                        this.$message.error(res.data.data);
                        // 不展示流程
                        this.showProcess = false;
                        this.processTips = res.data.data;
                    }
                });
            });
        }, 200),

        delProcessPeople(data, value) {
            const index = value.findIndex((i) => i.id === data.id);
            value.splice(index, 1);
        },
        /*
            提交申请
            submitForm            提交申请
            assemblyFormData      拼装表单提交数据
            assemblyProcessData   拼装流程提交数据
         */
        async submitForm() {
            if(this.ruleFormData.filter(item => item.compType == 'specialLeave').length > 0){
                let checkTimeRange = await this.$refs.formRef.$refs.specialLeave[0].checkLeaveForm()
                if (!checkTimeRange) {
                    return
                }
            }
            this.$refs.formRef.$refs.formRef.validate(async (val) => {
                if (val) {
                    if (
                        this.ruleForm.returnTime &&
                        this.ruleForm.departureTime &&
                        this.ruleForm.departureTime > this.ruleForm.returnTime
                    ) {
                        let departureTimeLabel = this.ruleFormData.find(
                            (i) => i.key === "departureTime",
                        ).label;
                        let returnTimeLabel = this.ruleFormData.find(
                            (i) => i.key === "returnTime",
                        ).label;
                        return this.$message.warning(
                            `${returnTimeLabel}必须大于${departureTimeLabel}`,
                        );
                    }
                    // 检查是否有正在上传的附件
                    for (let item of this.ruleFormData) {
                        if (item.compType === "attachmentUpload") {
                            let uploadingFiles = null;
                            if(this.ruleForm[item.key]){
                                uploadingFiles = this.ruleForm[item.key].find(e => e.suffix === 'loading')
                            }
                            if (uploadingFiles) {
                                this.$message.warning(`请等待${item.label}文件上传完成`)
                                return
                            }
                        }
                    }
                    const campusOA = new CampusOAModel();
                    // 必填校验
                    // 判断流程必填信息是否填写
                    const requiredData = this.processUserContent.filter(
                        (i) => i.processType === 2,
                    );
                    const find = requiredData.find(
                        (i) =>
                            i.value.length === 0 &&
                            i.person.filter((i) => i.id).length === 0,
                    );
                    if (find) {
                        return this.$message.warning(
                            `请选择${find.designName}`,
                        );
                    }
                    let obj = {
                        schoolId: this.schoolId,
                        formId: this.formInfo.id,
                        applyContent: this.assemblyFormData(),
                        processUserContent: this.assemblyProcessData(),
                    };
                    // 修改
                    if (this.OAQueryData.dataTitle && this.OAQueryData.formId) {
                        obj.id = this.OAQueryData.id;
                        obj.dataTitle = this.OAQueryData.dataTitle;
                        if(obj && obj.applyContent && obj.applyContent.applicant && obj.applyContent.applicant.type == 'applicantStu'){
                            obj.applyContent.applicant.type = 'applicant'
                        }
                        if(this.ruleFormData.filter(item => item.compType == 'specialLeave').length > 0){
                            this.handleSpecialLeaveData(obj.applyContent,this.$refs.formRef.$refs.specialLeave[0].leaveArray);
                        }

                        for (var key in obj.applyContent) {
                            let type = obj.applyContent[key].type;
                            let content = obj.applyContent[key].displayValue;
                            switch (type) {
                                case "applicant": // 申请人
                                case "applicantStu":
                                    await campusOA.getUserByOrgan(content).then((res) => {
                                            if (res.data.code == "200") {
                                                console.log(res.data.data,"返回申请人");
                                                if (res.data.data.length > 0) {
                                                    res.data.data.forEach((item) => {
                                                            item.key =item.type;
                                                        },
                                                    );
                                                    obj.applyContent[key].displayValue = content;
                                                    obj.applyContent[key].displayValue.forEach((iv)=>{
                                                        iv.key = iv.type;
                                                    })
                                                    obj.applyContent[key].value = res.data.data;
                                                }
                                            } else if (
                                                res.data &&
                                                res.data.msg
                                            ) {
                                            }
                                        });
                                    break;
                                case "personnelInput": // 人员
                                    await campusOA
                                        .getUserByOrgan(content)
                                        .then((res) => {
                                            if (res.data.code == "200") {
                                                obj.applyContent[
                                                    key
                                                ].displayValue = content;
                                            } else if (
                                                res.data &&
                                                res.data.msg
                                            ) {
                                            }
                                        });
                                    break;
                            }
                        }

                        let len = obj.processUserContent.length;
                        for (let i = 0; i < len; i++) {
                            let person = obj.processUserContent[i].person,
                                person1 = person.filter((item) => {
                                    return (
                                        item.organType && item.type == "organ"
                                    );
                                });

                            await campusOA
                                .getUserByOrgan(person1)
                                .then((res) => {
                                    if (res.data.code == "200") {
                                        obj.processUserContent[i].person =
                                            person
                                                .concat(res.data.data)
                                                .filter((item) => {
                                                    return item.type != "organ" && Object.entries(item).length > 0;
                                                });

                                    } else if (res.data && res.data.msg) {
                                    }
                                });
                        }
                        if(this.ruleFormData.filter(item => item.compType == 'specialLeave').length > 0){
                            this.isOverLeaveUnit = false;
                        } else {
                            // 再次调取获取余额的接口
                            // 判断时长与余额的关系
                            let leaveTypeFormItemEdit = this.ruleFormData.find(
                                (e) => e.compType === "leaveType",
                            );
                            if (leaveTypeFormItemEdit) {
                                // 先把是否超出余额设置为false
                                this.isOverLeaveUnit = false;
                                this.isSubmitBtn = true;  // 能正常提交单子
                                // 清空请假类型上一次的提示
                                this.isShowRepeateForm = false;
                                this.showBalance = false;
                                this.overLeaveTime = 0;
                                this.$set(leaveTypeFormItemEdit, "tips", "")
                                let leaveStartTimeFormItem = this.ruleFormData.find(
                                    (e) => e.compType === "leaveStartTime",
                                );
                                if (leaveStartTimeFormItem) {
                                    this.$set(leaveStartTimeFormItem, "disabled", false);
                                }
                                let leaveEndTimeFormItem = this.ruleFormData.find(
                                    (e) => e.compType === "leaveEndTime",
                                );
                                if (leaveEndTimeFormItem) {
                                    this.$set(leaveEndTimeFormItem, "disabled", false);
                                }
                                await this.getHasOngiongApplicaion(leaveTypeFormItemEdit);
                                if (this.isShowRepeateForm) {
                                    this.$refs.formRef.$refs.formRef.validateField("leaveType");
                                    this.isSubmitBtn = false;
                                    return
                                }
                                // 在获取余额
                                await this.changeVacationLeaveType(leaveTypeFormItemEdit);
                            }
                        }
                        if (!this.isOverLeaveUnit) {
                            // 311/222 衡水中学/衡实特殊处理
                            let specialsSchoolId = this.specialHandling.map(item => {
                                return item.schoolId;
                            });
                            // 如果是 311 / 222 学校 ，提交时多传一个字段
                            if(specialsSchoolId.includes(this.schoolId)) {
                                // 判断申请人类型是否为 学生
                                if (obj.applyContent?.applicant?.displayValue[0].type == 'student') {
                                    let addContent = {
                                        admissionNeedApply: {
                                            label: "入校提交申请",
                                            type: "admissionNeedApply",
                                            value: "2"
                                        }
                                    }
                                    obj.applyContent = Object.assign({}, obj.applyContent, addContent)
                                    console.log('衡中的特殊学校',obj);
                                }
                            }
                            await campusOA.schoolProcessEdit(obj).then(async (res) => {
                                if (res.data.code === "200") {
                                    this.$message.success("修改申请成功");
                                    this.handleBack();
                                } else if (res.data && res.data.msg) {
                                    this.$message.error(res.data.msg);

                                    if(this.ruleFormData.filter(item => item.compType == 'specialLeave').length > 0){
                                        this.$refs.formRef.$refs.specialLeave[0].clearLeaveForm()
                                    }

                                    if(res.data.code == "202037"){//请假类型不可用
                                        await this.handleApplicantChange();
                                        this.dealRequiredForm(); // 必填项修改
                                        return
                                    }
                                    // res.data.code == '101204' || res.data.code == '101210'
                                    if(res.data.code == "101210" || res.data.code == '101204' || res.data.code == "101210"){//与历史表单存在重复
                                        // await this.handleApplicantChange();
                                        // this.dealRequiredForm(); // 必填项修改
                                        return
                                    }
                                    this.handleBack();
                                }
                            });
                        } else {
                            this.showProcess = false;
                        }
                    } else {
                        if(obj && obj.applyContent && obj.applyContent.applicant && obj.applyContent.applicant.type == 'applicantStu'){
                            obj.applyContent.applicant.type = 'applicant'
                        }
                        if(this.ruleFormData.filter(item => item.compType == 'specialLeave').length > 0){
                            this.handleSpecialLeaveData(obj.applyContent,this.$refs.formRef.$refs.specialLeave[0].leaveArray);
                        }
                        console.log(obj.applyContent, "提交的数据");

                        for (var key in obj.applyContent) {
                            let type = obj.applyContent[key].type;
                            let content = obj.applyContent[key].displayValue;
                            console.log(content,'cccccccccc')
                            switch (type) {
                                case "applicant": // 申请人
                                case "applicantStu": // 申请人
                                    await campusOA.getUserByOrgan(content).then((res) => {
                                        if (res.data.code == "200") {
                                            if (res.data.data.length > 0) {
                                                res.data.data.forEach((item) => {
                                                        item.key = item.type;
                                                    }
                                                );
                                                obj.applyContent[key].displayValue = content;
                                                obj.applyContent[key].displayValue.forEach((iv)=>{
                                                    iv.key = iv.type;
                                                })
                                                obj.applyContent[key].value = res.data.data;
                                            }
                                        } else if (res.data && res.data.msg ) {
                                            console.log(res.data.msg);
                                        }
                                    });
                                    break;
                                case "personnelInput": // 人员
                                    await campusOA
                                        .getUserByOrgan(content)
                                        .then((res) => {
                                            if (res.data.code == "200") {
                                                obj.applyContent[
                                                    key
                                                ].displayValue = content;
                                            } else if (
                                                res.data &&
                                                res.data.msg
                                            ) {
                                            }
                                        });
                                    break;
                            }
                        }

                        let len = obj.processUserContent.length;
                        for (let i = 0; i < len; i++) {
                            let person = obj.processUserContent[i].person,
                                person1 = person.filter((item) => {
                                    return (
                                        item.organType && item.type == "organ"
                                    );
                                });

                            await campusOA
                                .getUserByOrgan(person1)
                                .then((res) => {
                                    if (res.data.code == "200") {
                                        obj.processUserContent[i].person =
                                            person
                                                .concat(res.data.data)
                                                .filter((item) => {
                                                    return item.type != "organ" && Object.entries(item).length > 0;
                                                });

                                    } else if (res.data && res.data.msg) {
                                    }
                                });
                        }
                        if (!this.checkTimingCorrect(obj).status) {
                            return this.$message.info(this.checkTimingCorrect(obj).msg)
                        }
                        if(this.ruleFormData.filter(item => item.compType == 'specialLeave').length > 0){
                            this.isOverLeaveUnit = false;
                        } else {
                            console.log('this.ruleFormData',this.ruleFormData);
                            // 再次调取获取余额的接口
                            // 判断时长与余额的关系
                            let leaveTypeFormItem = this.ruleFormData.find(
                                (e) => e.compType === "leaveType",
                            );
                            if (leaveTypeFormItem) {
                                // 清空请假类型上一次的提示
                                this.isShowRepeateForm = false;
                                this.isSubmitBtn = true;  // 能正常提交单子
                                this.showBalance = false;
                                this.overLeaveTime = 0;
                                this.$set(leaveTypeFormItem, "tips", "")
                                let leaveStartTimeFormItem = this.ruleFormData.find(
                                    (e) => e.compType === "leaveStartTime",
                                );
                                if (leaveStartTimeFormItem) {
                                    this.$set(leaveStartTimeFormItem, "disabled", false);
                                }
                                let leaveEndTimeFormItem = this.ruleFormData.find(
                                    (e) => e.compType === "leaveEndTime",
                                );
                                if (leaveEndTimeFormItem) {
                                    this.$set(leaveEndTimeFormItem, "disabled", false);
                                }
                                await this.getHasOngiongApplicaion(leaveTypeFormItem);
                                if (this.isShowRepeateForm) {
                                    this.$refs.formRef.$refs.formRef.validateField("leaveType");
                                    this.isSubmitBtn = false; // 不能正常提交单子
                                    return
                                }
                                // 在获取余额
                                await this.changeVacationLeaveType(leaveTypeFormItem);
                            }
                        }
                        if (!this.isOverLeaveUnit) {
                            // 311/222 衡水中学/衡实特殊处理
                            let specialsSchoolId = this.specialHandling.map(item => {
                                return item.schoolId;
                            });
                            // 如果是 311 / 222 学校 ，提交时多传一个字段
                            if(specialsSchoolId.includes(this.schoolId)) {
                                // 判断申请人类型是否为 学生
                                if (obj.applyContent?.applicant?.displayValue[0].type == 'student') {
                                    let addContent = {
                                        admissionNeedApply: {
                                            label: "入校提交申请",
                                            type: "admissionNeedApply",
                                            value: "2"
                                        }
                                    }
                                    obj.applyContent = Object.assign({}, obj.applyContent, addContent)
                                    console.log('衡中的特殊学校',obj);
                                }
                            }
                            // 提交
                            await campusOA
                                .saveSchoolProcessApply(obj)
                                .then((res) => {
                                    this.handleRes(res,  () => {
                                        this.$message.success("提交申请成功");
                                        this.$emit("handleSubmit");
                                    },async () => {
                                        if(res.data.code == "202037"){//请假类型不可用
                                            await this.handleApplicantChange();
                                            this.dealRequiredForm(); // 必填项修改
                                        }
                                        if(res.data.code == "101210" || res.data.code == '101204' || res.data.code == "101210"){//与历史表单存在重复
                                            // await this.handleApplicantChange();
                                            // this.dealRequiredForm(); // 必填项修改
                                            return
                                        }
                                    }
                                    );
                                });
                        } else {
                            this.showProcess = false;
                        }
                    }
                }
            });
        },
        /**
         * @Description: 按照小时，则校验是否按照正确的小时进行选择
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2024-05-22 10:27:50
         */
        checkTimingCorrect (obj) {

            let leaveStartTime = this.getItem('leaveStartTime');
            let leaveEndTime = this.getItem('leaveEndTime');

            if (!this.checkIsHours(leaveStartTime, obj, 'leaveStartTime')) {
                return {
                    status: false,
                    msg: '开始时间请按照时间段进行选择'
                }
            }
            if (!this.checkIsHours(leaveEndTime, obj, 'leaveEndTime')) {
                return {
                    status: false,
                    msg: '结束时间请按照时间段进行选择'
                }
            }

            return {
                status: true
            }

        },
        getItem (key) {
            return this.ruleFormData.filter(item => item.compType === key)[0];
        },
        checkIsHours (data, obj, key) {
            if (data && data.fixedTime && data.fixedTime['on-off'] == '1' && data['value-format'] == 'yyyy-MM-dd HH:mm:ss') {

                let timeSetting = data.fixedTime.timeSetting;
                let timeGroup = timeSetting.map(item => item.time);
                let time = new Date(obj.applyContent[key].value);
                var hour = time.getHours();
                var minute = time.getMinutes();
                if(hour.toString().length == 1) {
                    hour = '0' + hour;
                }
                if(minute.toString().length == 1) {
                    minute = '0' + minute;
                }
                let timer = hour + ':' + minute;
                console.log(timer,timeGroup.includes(timer),'timer')

                if (timeGroup.includes(timer)) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        async getProcess1() {
            const campusOA = new CampusOAModel();
            const res = await campusOA.getProcess({
                schoolId: this.schoolId,
                formId: this.formInfo.id,
                applyContent: this.assemblyFormData(),
            });
            if (res.data && res.data.code === "200") {
                if (res.data.data instanceof Array) {
                    return res.data.data
                }
            } else {
                return false;
            }

        },
        handleSpecialLeaveData(oData,leaveArray) {
            //拼装数据
            leaveArray.forEach(fitem => {
                if (fitem.timeRange && fitem.timeRange.length > 0) {
                    fitem.times.forEach(sitem => {
                        sitem.startTime.value = sitem.timeRange[0];
                        sitem.endTime.value = sitem.timeRange[1];
                    })
                }
            })
            this.$set(oData, 'specialLeave', leaveArray)
        },
        isProcessDisabled() {
            this.showProcess = false;
        },
        // 判断时间段重叠
        async getIsRepeatCheckDate(leaveData) {
            let processUserContents = await this.getProcess1();
            let applyContent2 = this.assemblyFormData();
            // if(!applyContent2.applicant){
            //     console.log('this.$store.state',this.$store.state);
            //     applyContent2.applicant = {
            //         displayValue: [],
            //         label: "申请人",
            //         type: "applicant",
            //         value: [{
            //             id: this.$store.state.teacherId,
            //             key: "teacher",
            //             type: "teacher"
            //         }]
            //     }
            // }
            // if(applyContent2.applicant?.value == '') {
            //     applyContent2.applicant.value = [{
            //         id: this.$store.state.teacherId,
            //         type: "teacher",
            //         key: "teacher",
            //     }]
            // }
            let obj2 = {
                schoolId: this.schoolId,
                formId: this.formInfo.id,
                applyContent: applyContent2,
                processUserContent: processUserContents,
            };
            if (this.OAQueryData.id) {
                obj2.id = this.OAQueryData.id;
            }
            if(!applyContent2.applicant || applyContent2.applicant?.value == ''){
                obj2.applyId = this.$store.state.userId
            }
            this.handleSpecialLeaveData(obj2.applyContent,leaveData);
            const campusOA = new CampusOAModel();
            for (var key in obj2.applyContent) {
                let type1 = obj2.applyContent[key].type,
                    content1 = obj2.applyContent[key].displayValue;
                switch (type1) {
                    case "applicant": // 申请人
                        await campusOA
                            .getUserByOrgan(content1)
                            .then((res) => {
                                if (res.data.code == "200") {
                                    if (res.data.data.length > 0) {
                                        obj2.applyContent[
                                            key
                                        ].displayValue = content1;
                                        res.data.data.forEach(
                                            (item) => {
                                                item.key =
                                                    item.type;
                                            },
                                        );
                                        obj2.applyContent[
                                            key
                                        ].value = res.data.data;
                                    }
                                } else if (
                                    res.data &&
                                    res.data.msg
                                ) {
                                }
                            });
                        break;
                    case "personnelInput": // 人员
                        await campusOA
                            .getUserByOrgan(content1)
                            .then((res) => {
                                if (res.data.code == "200") {
                                    obj2.applyContent[
                                        key
                                    ].displayValue = content1;
                                } else if (
                                    res.data &&
                                    res.data.msg
                                ) {
                                }
                            });
                        break;
                }
            }
            let len1 = obj2.processUserContent.length;
            for (let i = 0; i < len1; i++) {
                let person2 = obj2.processUserContent[i].person,
                    person3 = person2.filter((item) => {
                        return (
                            item.organType && item.type == "organ"
                        );
                    })
                await campusOA
                    .getUserByOrgan(person3)
                    .then((res) => {
                        if (res.data.code == "200") {
                            obj2.processUserContent[i].person =
                                person2
                                    .concat(res.data.data)
                                    .filter((item) => {
                                        return item.type != "organ" && Object.entries(item).length > 0;
                                    })
                        } else if (res.data && res.data.msg) {
                        }
                    });
            }
            await campusOA
                .getProcessApplyCheckDateBySpecial(obj2)
                .then((res) => {
                    this.tipContent = res.data.data
                    if (this.tipContent && this.tipContent.name) {
                        this.showProcess = false;
                    }
                    this.$forceUpdate();
                })
        },
        // 改变结束时间，判断是否与已有的假条事件重叠
        async changeEndTimeChose(){
            let applyContent1 = this.assemblyFormData();
            if(applyContent1.leaveType?.value == '' || applyContent1.leaveStartTime?.value == '') return
            if(applyContent1.leaveType?.value == '' || applyContent1.leaveEndTime?.value == '') return

            // 如果没有申请人组件
            if (!applyContent1.applicant) {
                applyContent1.applicant = {
                    label: "申请人",
                    type: "applicant",
                    value: [{
                        id: this.$store.state.teacherId,
                        type: "teacher",
                        key: "teacher",
                    }]
                }
            }
            // 如果没选择'申请人'，value值为[]
            if(applyContent1.applicant?.value == '') {
                applyContent1.applicant.value = [{
                    id: this.$store.state.teacherId,
                    type: "teacher",
                    key: "teacher",
                }]
            }
            if(applyContent1.leaveEndTime?.value == '' || applyContent1.leaveEndTime?.value == null) {
                applyContent1.leaveEndTime.value = []
            }
            if(applyContent1.leaveDuration?.value == '') {
                applyContent1.leaveDuration.value = []
            }
            let leaveTypeFormItem = this.ruleFormData.find(
                (i) => i.key === "leaveType",
            );

            let obj1 = {
                schoolId: this.schoolId,
                formId: this.formInfo.id,
                applyContent: applyContent1,
                processUserContent: this.assemblyProcessData(),
            };
            if (this.OAQueryData.id) {
                obj1.id = this.OAQueryData.id;
            }
            const campusOA = new CampusOAModel();
            for (var key in obj1.applyContent) {
                let type1 = obj1.applyContent[key].type,
                    content1 = obj1.applyContent[key].displayValue;
                switch (type1) {
                    case "applicant": // 申请人
                        await campusOA
                            .getUserByOrgan(content1)
                            .then((res) => {
                                if (res.data.code == "200") {
                                    if (res.data.data.length > 0) {
                                        obj1.applyContent[
                                            key
                                        ].displayValue = content1;
                                        res.data.data.forEach(
                                            (item) => {
                                                item.key =
                                                    item.type;
                                            },
                                        );
                                        obj1.applyContent[
                                            key
                                        ].value = res.data.data;
                                    }
                                } else if (
                                    res.data &&
                                    res.data.msg
                                ) {
                                }
                            });
                        break;
                    case "personnelInput": // 人员
                        await campusOA
                            .getUserByOrgan(content1)
                            .then((res) => {
                                if (res.data.code == "200") {
                                    obj1.applyContent[
                                        key
                                    ].displayValue = content1;
                                } else if (
                                    res.data &&
                                    res.data.msg
                                ) {
                                }
                            });
                        break;
                }
            }
            let len1 = obj1.processUserContent.length;
            for (let i = 0; i < len1; i++) {
                let person2 = obj1.processUserContent[i].person,
                    person3 = person2.filter((item) => {
                        return (
                            item.organType && item.type == "organ"
                        );
                    })
                await campusOA
                    .getUserByOrgan(person3)
                    .then((res) => {
                        if (res.data.code == "200") {
                            obj1.processUserContent[i].person =
                                person2
                                    .concat(res.data.data)
                                    .filter((item) => {
                                        return item.type != "organ" && Object.entries(item).length > 0;
                                    })
                        } else if (res.data && res.data.msg) {
                        }
                    });
            }


            // 判断
            await campusOA
                .getProcessApplyCheckApplyDate(obj1)
                .then((res) => {
                    this.handleRes(res, () => {
                        this.isRepeate = res.data.data
                        this.showProcess = false;
                    });
                });
        },
        // 走读申请套件 处理开始、结束日期
        async checkoutDateIsReapt(){
            //dayStudentStartDate dayStudentEndDate
            let applyContent1 = this.assemblyFormData();
            let obj1 = {
                schoolId: this.schoolId,
                formId: this.formInfo.id,
                applyContent: applyContent1,
                processUserContent: this.assemblyProcessData(),
                formType :4
            };
            if (this.OAQueryData.id) {
                obj1.id = this.OAQueryData.id;
            }
            const campusOA = new CampusOAModel();
            for (var key in obj1.applyContent) {
                let type1 = obj1.applyContent[key].type,
                    content1 = obj1.applyContent[key].displayValue;
                switch (type1) {
                    case "applicant": // 申请人
                        await campusOA
                            .getUserByOrgan(content1)
                            .then((res) => {
                                if (res.data.code == "200") {
                                    if (res.data.data.length > 0) {
                                        obj1.applyContent[
                                            key
                                        ].displayValue = content1;
                                        res.data.data.forEach(
                                            (item) => {
                                                item.key =
                                                    item.type;
                                            },
                                        );
                                        obj1.applyContent[
                                            key
                                        ].value = res.data.data;
                                    }
                                } else if (
                                    res.data &&
                                    res.data.msg
                                ) {
                                }
                            });
                        break;
                    case "personnelInput": // 人员
                        await campusOA
                            .getUserByOrgan(content1)
                            .then((res) => {
                                if (res.data.code == "200") {
                                    obj1.applyContent[
                                        key
                                    ].displayValue = content1;
                                } else if (
                                    res.data &&
                                    res.data.msg
                                ) {
                                }
                            });
                        break;
                }
            }
            let len1 = obj1.processUserContent.length;
            for (let i = 0; i < len1; i++) {
                let person2 = obj1.processUserContent[i].person,
                    person3 = person2.filter((item) => {
                        return (
                            item.organType && item.type == "organ"
                        );
                    })
                await campusOA
                    .getUserByOrgan(person3)
                    .then((res) => {
                        if (res.data.code == "200") {
                            obj1.processUserContent[i].person =
                                person2
                                    .concat(res.data.data)
                                    .filter((item) => {
                                        return item.type != "organ" && Object.entries(item).length > 0;
                                    })
                        } else if (res.data && res.data.msg) {
                        }
                    });
            }


            // 判断
            await campusOA
                .getProcessApplyCheckDateBySpecial(obj1)
                .then((res) => {
                    // this.handleRes(res, ()=>{
                    //     this.isRepeate = false
                    //     this.tipContent1 = ''
                    //     console.log('进入')
                    // },() => {
                    //     this.isRepeate = true
                    //     this.tipContent1 = res.data.msg
                    //     this.showProcess = false;
                    // });
                    if (res.data.code == '101204' || res.data.code == '101210') {
                        this.tipContent1 = res.data.msg
                        this.showProcess = false;
                        this.isRepeate = true
                    }else{
                        this.isRepeate = false
                        this.tipContent1 = ''
                    }
                    this.$forceUpdate();

                })
        },
        assemblyFormData() {
            // 表单项内容
            let applyContent = {};
            this.ruleFormData.forEach((item,index) => {
                const data = this.ruleForm[item.key];
                if (["personnelInput", "applicant", "applicantStu", "shiftWorker"].includes(item.compType)) {
                    // 申请人 人员 换班人
                    let list = data.filter((i) => i.key !== "organ");
                    applyContent[item.key] = {
                        displayValue: list,
                        label: item.label,
                        type: item.compType,
                        value: list,
                        sort: index+1,
                    };
                } else if (
                    ["approvalFormSelect", "multipleSelect"].includes(
                        item.compType,
                    )
                ) {
                    //关联审批单 多选
                    applyContent[item.key] = {
                        displayValue: item.options.filter(
                            (i) =>
                                data.find((it) => it == i.value) !== undefined,
                        ),
                        type: item.compType,
                        value: data.join(","),
                        label: item.label,
                        sort: index+1,
                    };
                } else if (
                    ["radio", "entryExitSelect", "pickDropPersons","studentType"].includes(
                        item.compType,
                    )
                ) {
                    // 单选 出入类型  学生类型
                    applyContent[item.key] = {
                        displayValue: item.options.find((i) => data == i.value),
                        type: item.compType,
                        value: data,
                        label: item.label,
                        sort: index+1,
                    };
                } else if (["leaveType"].includes(item.compType)) {
                    applyContent[item.key] = {
                        displayValue: item.options.find((i) => data == i.value),
                        type: item.compType,
                        value: data,
                        label: item.label,
                        isInSetting: item.isInSetting,
                        sort: index+1,
                    };
                } else if (item.compType === "address") {
                    // 地址
                    applyContent[item.key] = {
                        type: item.compType,
                        value: data.value.map((item, index) => ({
                            areaCode: item,
                            areaName: data.label[index],
                        })),
                        label: item.label,
                        sort: index+1,
                    };
                } else if (item.compType === "upload") {
                    // 上传
                    applyContent[item.key] = {
                        displayValue: item.uploadValue,
                        type: item.compType,
                        value: data.join(","),
                        label: item.label,
                        sort: index+1,
                    };
                } else if (item.compType === "date") {
                    // 时间
                    if (item.type === "datetime") {
                        applyContent[item.key] = {
                            type: item.compType,
                            value: data,
                            label: item.label,
                            sort: index+1,
                        };
                    } else if (item.type === "daterange") {
                        applyContent[item.key] = {
                            displayValue: data,
                            type: item.compType,
                            value: data.join(","),
                            label: item.label,
                            sort: index+1,
                        };
                    }
                } else if (item.compType === "attachmentUpload") {
                    applyContent[item.key] = {
                        type: item.compType,
                        value: Array.isArray(data) ? data.map(i => i.name).toString() : '',
                        label: item.label,
                        displayValue: data,
                        sort: index+1,
                    };
                } else if (["leaveStartTime", "leaveEndTime"].includes(item.compType)) {
                    applyContent[item.key] = {
                        type: item.compType,
                        value: data,
                        label: item.label,
                        sort: index+1,
                    };
                    // 请假类型是半天的，赋上noonType
                    let leaveTypeFormItem = this.ruleFormData.find(e => e.key === "leaveType")
                    if (leaveTypeFormItem && this.ruleForm.leaveType) {
                        let leaveTypeOptionSelected = leaveTypeFormItem.options.find((e) => e.value === this.ruleForm.leaveType)
                        if (leaveTypeOptionSelected && leaveTypeOptionSelected.leaveUnit == '3') {
                            applyContent[item.key].noonType = this.ruleForm[item.key + "NoonType"]
                        }
                    }
                } else {
                    applyContent[item.key] = {
                        type: item.compType,
                        value: data,
                        label: item.label,
                        sort: index+1,
                    };
                }
            });
            return applyContent;
        },
        assemblyProcessData() {
            let userContent = [];
            let data = JSON.parse(JSON.stringify(this.processUserContent));
            data = data.filter(e => e.designId != "shiftWorkerAudit") // 流程提交时去掉手动添加的换班人
            data.forEach((item) => {
                item.person = [
                    ...item.person.filter((i) => i.id),
                    ...item.value,
                ].filter((i) => i.key !== "organ" &&  Object.entries(i).length > 0);
                delete item.value;

                if(Object.keys(item).length > 0) {
                    userContent.push(item);
                }

            });
            return userContent;
        },

        /*
           获取地址相关方法
           getOptionsData        获取省份数据
           getSubOptionsData     获取市县数据
           addressChange         获取当前选中地址名称
         */
        getOptionsData(obj) {
            this._get("/operate/common/getAreaList").then((res) => {
                obj.optionsData = res.data.data.map((item) => ({
                    label: item.areaName,
                    value: item.areaCode.toString(),
                    children: [],
                }));
                this.setDefaultAddressValue(obj);
            });
        },
        getSubOptionsData(filterDate) {
            if (!filterDate.id) {
                return;
            }
            this._get(
                "/operate/common/getAreaList?parentCode=" + filterDate.id,
            ).then((res) => {
                let data = res.data.data.map((item) => ({
                    label: item.areaName,
                    value: item.areaCode.toString(),
                    leaf: filterDate.level >= 2,
                }));
                filterDate.fn(data);
            });
        },
        addressChange(argus) {
            let {data, ref} = argus;
            this.ruleForm[data.key].label = ref[data.key][0]
                .getCheckedNodes()[0]
                .pathNodes.map((i) => i.label);
            this.handleChange(data);
        },

        // 返回/取消
        handleBack() {
            this.$emit("handleBack");
        },
        // 接口请求结果处理
        handleRes(res, fn, errorFn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
                !!errorFn && errorFn();
            }
        },
        // 人员选择弹窗
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-04-23 10:44:16
         */
        async getTreeDataList(type) {
            const commonModel = new CommonModel();
            let o = {
                ...this.treeData,
            }
            await commonModel.getAddressBookList(o).then((res) => {
                if (res.data.code == "200") {
                    this.selTreeOptList = res.data.data;
                    if (type) {
                        this.selectObjData = this.selTreeOptList;
                    }
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        async getTreeDataList1(type) {
            const commonModel = new CommonModel();
            let o = {
                ...this.treeData,
                jobScope:[2,3]
            }
            await commonModel.getAddressBookList1(o).then((res) => {
                if (res.data.code == "200") {
                    this.selTreeOptList1 = res.data.data;
                    if(this.selTreeOptList1.length > 0 && this.selTreeOptList1[0].organType == '4') {//返回的是班
                        this.selTreeOptList1.forEach(item => {
                            if(item.classFlag == null) {
                                item.classFlag = '0'
                            }
                        })
                        this.selTreeOptList1 = this.selTreeOptList1.sort((a, b) => b.classFlag - a.classFlag)
                    }
                    if(type) {
                        setTimeout(() => {
                            if(type == 'list') {//首次加载列表
                                if(this.selTreeOptList1.length == 1){//只有一个班
                                    this.$refs.treeShape.breadcrumbsList1 = [];
                                    this.$refs.treeShape.treeNodeNextLevelClick1({treeNode: this.selTreeOptList1[0]})
                                }else{
                                    if(this.selTreeOptList1[0].parentName){//有父级
                                        this.$refs.treeShape.breadcrumbsList1[0].name = this.selTreeOptList1[0].parentName;
                                    }
                                }
                            }
                            // if(type == 'next') {//点击下级
                            //     if(this.selTreeOptList1.length == 1 && this.selTreeOptList1[0].type == 'organ'){//只有一个下级并且是组织，自动展开这个下级的下级
                            //         this.$refs.treeShape.treeNodeNextLevelClick1({treeNode: this.selTreeOptList1[0]})
                            //     }
                            // }
                        })
                    }
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        async getNextLevelTreeList(data) {
            const {id, name, type} = data;
            this.treeData.parentId = id || null;
            this.treeData.name = name;
            if(this.dialogObj.dialogVisible){
                await this.getTreeDataList();
                this.$eventBroadcast("getNewTreeList");
            }
            if(this.dialogObj1.dialogVisible){
                if(type == 'search'){
                    this.treeData.parentId = '';
                }
                await this.getTreeDataList1();
                this.$eventBroadcast("getNewTreeList1");
            }
        },
        async getNextLevelTreeList1(data) {
            const {id, name} = data;
            this.treeData.parentId = id || null;
            this.treeData.name = name;
            await this.getTreeDataList1('next');
            this.$eventBroadcast("getNewTreeList1");
        },
        determine(data) {
            if (this.assembliesPersonType == "copied") {
                let ar1 = [];
                data.map((item) => {
                    let idx = this.assembliesPerson.findIndex((subItem) => {
                        return item.id == subItem;
                    });
                    if (idx < 0) {
                        ar1.push(item);
                    }
                });
                this.setTreeAuxiliaryData.data = ar1;
            } else {
                this.setTreeAuxiliaryData.data = data;
            }
            if (this.multiple) {
                this.setTreeAuxiliaryData.selList = data
                    .map((item) => {
                        return item.name;
                    })
                    .toString();
            }
            this.setTreeData(this.setTreeAuxiliaryData);
            removeTreeShapeDialog();
            this.dialogObj.dialogVisible = false;
            this.dialogObj1.dialogVisible = false;
            this.treeData.parentId = "";
            this.treeData.name = "";
            setTimeout(() => {
                this.resetAuxiliaryData();
            });
        },
        resetAuxiliaryData() {
            this.setTreeAuxiliaryData.key = "";
            this.setTreeAuxiliaryData.data = [];
            this.setTreeAuxiliaryData.selList = "";
        },
        /**
         * @Description: 关闭弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-23 10:44:50
         */
        closeDialog() {
            this.dialogObj.dialogVisible = false;
            this.dialogObj1.dialogVisible = false;
            removeTreeShapeDialog();
            this.treeData.parentId = "";
            this.treeData.name = "";
        },
        /**
         * @Description: 检查当前检查类型是否有效
         * @DoWhat:如果无效则清空请假类型及相关数据
         * @UseScenarios:
         * @Attention:
         * @Author: 张冰华
         * @Date: 2024-02-21 10:44:50
         */
        async checkleaveType(data) {
            let isHave = true;
            const leaveTypeOption = data.options.find(
                (e) => e.value === this.ruleForm.leaveType,
            );
            if (!leaveTypeOption) {
                // 如果请假类型无效，则清空数据
                await this.handleApplicantChange();
                this.dealRequiredForm(); // 必填项修改
                isHave = false;
            }
            return isHave;
        },
        /**
         * @Description: 选择了请假类型，获取余额详情
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-05-11 16:49:31
         */
        async changeVacationLeaveType(data){
            this.isShowRepeateForm = false;
            this.isOverLeaveUnit = false;
            this.isSubmitBtn = true;
            this.isDisabledBtn = false;  // 是否禁用开始时间、结束时间
            console.log('this.ruleFormData',this.ruleFormData,'this.ruleForm',this.ruleForm);
            // 初始化提示信息和调休数据
            this.$set(data, "tips", "")
            let leaveStartTimeFormItem = this.ruleFormData.find(
                (e) => e.compType === "leaveStartTime",
            );
            if (leaveStartTimeFormItem) {
                this.$set(leaveStartTimeFormItem, "disabled", false);
            }
            let leaveEndTimeFormItem = this.ruleFormData.find(
                (e) => e.compType === "leaveEndTime",
            );
            if (leaveEndTimeFormItem) {
                this.$set(leaveEndTimeFormItem, "disabled", false);
            }
            this.overLeaveTime = this.$options.data.call(this).overLeaveTime;
            const commonOAModel = new CampusOAModel();
            let nowLeaveTypeInfo = this.useVacationList.find(e => e.id == this.ruleForm.leaveType)
            if (nowLeaveTypeInfo && nowLeaveTypeInfo.balanceSwitch && nowLeaveTypeInfo.balanceSwitch == 1) {
                const leaveTeacherId = this.ruleForm.applicant && this.ruleForm.applicant.length > 0
                ? this.ruleForm.applicant[0].id
                : this.$store.state.teacherId;
                if (!leaveTeacherId) {
                    this.showProcess = false
                    this.isShowRepeateForm = false;
                    this.showBalance = false;
                    this.isOverLeaveUnit = false;
                    this.overLeaveTime = 0;
                    return
                }
                let balanceForm = {}
                let editFormData = {
                    schoolId: this.schoolId,
                    vacationId: this.ruleForm.leaveType,
                    teacherId: leaveTeacherId,
                    applyId: this.OAQueryData.id,
                    isEdit: '1'
                }
                let oriFormData = {
                    schoolId: this.schoolId,
                    vacationId: this.ruleForm.leaveType,
                    teacherId: leaveTeacherId
                }
                balanceForm = oriFormData;
                // 如果是审批通过的单子，修改
                if (this.schoolProcessInfo.approveStats == '2') {
                    balanceForm = editFormData
                }
                await commonOAModel.getVacationLeaveBalance(balanceForm).then((res) => {
                    if (res.data.code == "200") {
                        this.isSubmitBtn = true;
                        this.showBalance = true;
                        this.isDisabledBtn = false;  // 是否禁用开始时间、结束时间
                        
                        // 设置剩余调休时长和请假单位
                        const { leaveBalance,leaveUnit } = res.data.data;
                        this.cLeaveDay.leaveUnit = leaveUnit;
                        // 获取请假类型名称
                        let options = data.options.find((i) => i.value === this.ruleForm.leaveType)
                        this.cLeaveDay.name = options ? options.label : "";
                        if (this.cLeaveDay.name.includes("调休")) {

                            this.cLeaveDay.leaveUnit = leaveUnit;
                            // 修改请假开始时间请假结束时间格式
                            this.ruleFormData.forEach((item) => {
                                // 如果请假类型设置了余额，并且是 【调休】，选择时间组件单位展示余额的单位
                                if (this.showBalance && this.cLeaveDay.leaveUnit) {
                                    let unitFormat = '';
                                    let unitValueFormat = '';
                                    let unitStatus = '';
                                    let unitStatusMap = {
                                        1: "date",
                                        2: "datetime",
                                        3: "dateHalf"
                                    }
                                    unitStatus = unitStatusMap[leaveUnit];
                                    unitFormat =
                                        this.cLeaveDay.leaveUnit === 1
                                            ? "yyyy-MM-dd"
                                            : "yyyy-MM-dd HH:mm";
                                    unitValueFormat =
                                        this.cLeaveDay.leaveUnit === 1
                                            ? "yyyy-MM-dd"
                                            : "yyyy-MM-dd HH:mm:ss";

                                    // 修改请假开始时间请假结束时间格式
                                    const compTypeList1 = ["leaveStartTime", "leaveEndTime"];
                                    if (compTypeList1.includes(item.compType)) {
                                        item["status"] = unitStatus;
                                        item["value-format"] = unitValueFormat;
                                        item["format"] = unitFormat;
                                        item["default-time"] =
                                            item.compType === "leaveStartTime"
                                                ? "00:00:00"
                                                : "23:59:59";
                                    }
                                    let durationDataForm = this.ruleFormData.find(
                                        (i) => i.key === "leaveDuration",
                                    );
                                    let chooseLeaveUnit = leaveUnit == "2" ? "小时" : "天";
                                    this.$set(durationDataForm, "unit", chooseLeaveUnit);

                                    this.$set(options, "leaveUnit", leaveUnit);
                                }
                            });
                        }
                        if (typeof res.data.data.leaveBalance != "undefined") {
                            this.overLeaveTime = leaveBalance;
                            if ([1, 2, 3].includes(leaveUnit)) {
                                const unitName = {
                                    1: "天",
                                    2: "小时",
                                    3: "天"
                                }[+leaveUnit] || ""
                                // 设置请假类型表单项的提示信息
                                if (!this.isShowRepeateForm && this.showBalance) {
                                    this.$set(data, "tips", `余额${leaveBalance}${unitName}`)
                                }
                                if (this.ruleForm.leaveDuration > this.overLeaveTime) {
                                    this.$message.warning('余额不够用');
                                    this.isOverLeaveUnit = true;
                                    this.$set(this.ruleForm, "leaveStartTime", "")
                                    this.$set(this.ruleForm, "leaveEndTime", "")
                                    this.$set(this.ruleForm, "leaveDuration", "")
                                    this.leaveDurationDetailsBtnShow = false
                                    this.isSubmitBtn = false;
                                } else {
                                    this.isOverLeaveUnit = false;
                                }
                            }
                        }
                    } else if (res.data.code == "022501") {
                        this.isSubmitBtn = true;
                        this.showBalance = false;
                        this.isOverLeaveUnit = false;
                    } else if (res.data.code == "022502") {
                        this.$message.error(res.data.msg);
                        this.isDisabledBtn = true;
                        if (this.isDisabledBtn) {
                            console.log('不属于规则',this.isDisabledBtn);
                            this.$set(leaveStartTimeFormItem, "disabled", true);
                            this.$set(leaveEndTimeFormItem, "disabled", true);
                            this.leaveDurationDetailsBtnShow = false
                        }
                        this.isSubmitBtn = false;
                    } else if (res.data && res.data.msg) {
                        this.isSubmitBtn = false;
                        this.$message.error(res.data.msg);
                    }
                })
            } else {
                this.overLeaveTime = 0;
            }
        },
        /**
         * @Description: 判断是否有审批中请假单（设置了假期余额balanceSwitch为1请求）
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2024-05-11 18:27:31
         */
        async getHasOngiongApplicaion(data){
            this.isShowRepeateForm = false;
            this.$refs.formRef.$refs.formRef.clearValidate();
            // 初始化提示信息和调休数据
            this.$set(data, "tips", "")
            const commonOAModel = new CampusOAModel();
            let nowLeaveTypeInfo = this.useVacationList.find(e => e.id == this.ruleForm.leaveType)
            if (nowLeaveTypeInfo && nowLeaveTypeInfo.balanceSwitch && nowLeaveTypeInfo.balanceSwitch == 1) {
                // 根据申请人或从存储中获取教师ID
                const HasOngiongTeacherId = this.ruleForm.applicant && this.ruleForm.applicant.length > 0
                        ? this.ruleForm.applicant[0].id
                        : this.$store.state.teacherId;
                if (!HasOngiongTeacherId) {

                    this.showProcess = false
                    this.isShowRepeateForm = false;
                    this.showBalance = false;
                    return
                }
                let hasOngiongForm = {
                    schoolId: this.schoolId,
                    vacationId: this.ruleForm.leaveType,
                    teacherId: HasOngiongTeacherId,
                    id: this.OAQueryData.id
                }
                if (!this.OAQueryData.id) {
                    delete hasOngiongForm.id
                }
                await commonOAModel.getProcessHasOngiongApplicaion(hasOngiongForm).then((res) => {
                    if (res.data.code == "200") {
                        this.isShowRepeateForm = res.data.data
                        if (this.isShowRepeateForm) {
                            this.showBalance = false;
                        }
                        this.showProcess = false;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                })
            } else {

            }

        },

    }
};
</script>
<style lang="scss" scoped>
.form-detail-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    overflow: hidden;

    .form-detail-wrap {
        height: 100%;
        padding: 10px;
        overflow: auto;

        .form-detail {
            min-height: 100%;
            max-width: 960px;
            background-color: #fff;
            padding: 48px 5%;
            margin: 0 auto;
            box-sizing: border-box;
            box-shadow: -2px 0 8px 0 #E1E3E6;
            border-radius: 4px;
            overflow: hidden;
        }
    }

    .btn-group {
        flex-shrink: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 54px;
        background: #FFFFFF;
        box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.1);
        z-index: 99;
    }
}
</style>
